import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Hidden, Popover, IconButton, makeStyles } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import styles from "./styles";
import ShareIcon from "@material-ui/icons/Share";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CircularProgress from "@material-ui/core/CircularProgress";

import HourGlassIcon from "../../assets/icon/hourglass.png";
import LoadingSpinner from "../components/LoadingSpinner";
import { CurrencyValue } from "../../v2_components/CurrencyValue";
// import LowHighResPhotoPrompt from "../../v2_components/LowHighResPhotoPrompt";
import LowHighResPhotoPrompt from "../../v3_components/LowHighResPhotoPrompt";
import { useIsMobile } from "../../actions/ui";
import { trolleyList } from "../../v2_actions/trolley";
import {
  trolleyPhotographList,
  CROSS_CURRENCY_ERROR,
} from "../../v2_actions/trolley_photograph";
import {
  setCustomValue,
  CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
  getCustomValue,
  CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
} from "../../v2_actions/custom_value";
import { CheckoutButton } from "../../v2_components/CheckoutButton";

const DEFAULT_SHOPPING_CART_ICON_COLOR = "white";
const SELECTED_SHOPPING_CART_ICON_COLOR = "red";
const SELECTING_SHOPPING_CART_ICON_COLOR = "#ff2727";
const ONE_SELECTED_PHOTOGRAPH_IN_TROLLEY = 1;

const useStyles = makeStyles(styles);

const InnerLabel = ({
  width,
  photoId,
  price,
  gallery,
  selected,
  showIcons,
  photographer,
  trolleyPhotograph,
  showPhotographTimeTakenBox,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const trolley_id = useSelector((state) => state.trolleyReducer.trolley_id)
  const trolley = useSelector(() =>
    trolleyList.getObject(trolley_id),
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isSelecting, setIsSelecting] = useState(false);
  const createdNewTrolley = !selected && !isSelecting;
  const [isDeselecting, setIsDeselecting] = useState(false);
  const [trolleyIsEmpty, setTrolleyIsEmpty] = useState(false);
  const [isResettingTrolley, setIsResettingTrolley] = useState(false);
  const galleryLowResPrice = get(gallery, ["gallery_price", "low_res_price"]);
  const [tryShowLowHighResPhotoPrompt, setTryShowLowHighResPhotoPrompt] =
    useState(false);
  const [shoppingCartIconColor, setShoppingCartIconColor] = useState(
    DEFAULT_SHOPPING_CART_ICON_COLOR,
  );
  const [
    tryingToAddCrossPhotographerTrolleyPhotograph,
    setTryingToAddCrossPhotographerTrolleyPhotograph,
  ] = useState(null);
  const isUpdatingTrolley = useSelector(
    () =>
      trolleyPhotographList.getIsSavingObject() ||
      trolleyPhotographList.getIsDeletingObject(),
  );
  const numberOfSelectedPhotographsFromApi = useSelector((state) =>
    get(trolley, "number_of_photos", 0),
  );
  const numberOfSelectedPhotographsFromState = useSelector(
    (state) =>
      getCustomValue({ state, name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS }) ||
      0,
  );

  const waitingForApiCallAddingPhotographToTrolley =
    tryShowLowHighResPhotoPrompt &&
    numberOfSelectedPhotographsFromApi === 0 &&
    !get(trolleyPhotograph, "id");

  useEffect(() => {
    dispatch(trolleyList.ensureObjectLoaded(trolley_id))
  }, [trolley_id])

  useEffect(() => {
    if (!get(trolleyPhotograph, "id")) {
      setTryShowLowHighResPhotoPrompt(false);
    }
  }, [trolleyPhotograph]);

  const conditionsToRenderLowResPromptAreMet =
    tryShowLowHighResPhotoPrompt &&
    trolleyIsEmpty &&
    numberOfSelectedPhotographsFromState === ONE_SELECTED_PHOTOGRAPH_IN_TROLLEY;

  useEffect(() => {
    if (isEmpty(get(trolley, "trolley_photographs"))) {
      setTrolleyIsEmpty(true);
    } else {
      setTrolleyIsEmpty(false);
    }
  }, [tryShowLowHighResPhotoPrompt]);

  useEffect(() => {
    if (selected) {
      setIsSelecting(false);
      setShoppingCartIconColor(SELECTED_SHOPPING_CART_ICON_COLOR);
    }
    if (isDeselecting || createdNewTrolley) {
      setIsSelecting(false);
      setShoppingCartIconColor(DEFAULT_SHOPPING_CART_ICON_COLOR);
    }
    if (isSelecting) {
      setIsDeselecting(false);
      setShoppingCartIconColor(SELECTING_SHOPPING_CART_ICON_COLOR);
    }
  }, [isDeselecting, selected, isSelecting]);

  useEffect(() => {
    if (!isUpdatingTrolley) {
      setIsDeselecting(false);
    }
  }, [selected]);

  const doesGalleryQualifyForAskingLowResQuestion = () => {
    return galleryLowResPrice !== null;
  };

  const onAddPhotographToNewTrolley = () => {
    setIsResettingTrolley(true);
    dispatch(trolleyList.resetTrolley());
    if (tryingToAddCrossPhotographerTrolleyPhotograph) {
      dispatch(
        trolleyList.resetTrolley(function () {
          dispatch(
            trolleyPhotographList.addPhotograph(
              tryingToAddCrossPhotographerTrolleyPhotograph,
            ),
          );
          setTryingToAddCrossPhotographerTrolleyPhotograph(null);
          setIsResettingTrolley(false);

          if (doesGalleryQualifyForAskingLowResQuestion()) {
            setTryShowLowHighResPhotoPrompt(true);
          }
        }),
      );
    }
  };

  const onCheckoutPreviousTrolley = () => {
    navigate("/customer/v2_trolley");
    setTryingToAddCrossPhotographerTrolleyPhotograph(null);
  };

  const hideLowHighResPhotoPrompt = () => {
    setTryShowLowHighResPhotoPrompt(false);
  };

  const openDigitalPricingDialog = () => {
    dispatch(
      setCustomValue({
        name: CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
        value: true,
      }),
    );
  };

  const onSelectPhotoForBuy = (photograph_id) => {
    if (isUpdatingTrolley || waitingForApiCallAddingPhotographToTrolley) {
      return;
    }

    if (selected === true) {
      setIsDeselecting(true);
      dispatch(trolleyPhotographList.removePhotograph(photograph_id));
    } else {
      setIsSelecting(true);
      const addPhotographToTrolley =
        trolleyPhotographList.canAddPhotographToTrolley(
          gallery,
          trolley,
          photographer,
        );
      if (get(addPhotographToTrolley, "can_add")) {
        dispatch(trolleyPhotographList.addPhotograph(photograph_id));

        if (doesGalleryQualifyForAskingLowResQuestion()) {
          setTryShowLowHighResPhotoPrompt(true);
        }
      } else {
        setIsSelecting(false);
        if (get(addPhotographToTrolley, "reason") === CROSS_CURRENCY_ERROR) {
          setTryingToAddCrossPhotographerTrolleyPhotograph(photograph_id);
        }
      }
    }
  };

  const renderSelectingLoader = () => {
    return (
      <div className={classes.labelIsSelectingLoader}>
        <CircularProgress />
      </div>
    );
  };

  const tryRenderCrossPhotographerWarning = () => {
    return (
      <>
        {isResettingTrolley && <LoadingSpinner />}

        <Dialog
          open={tryingToAddCrossPhotographerTrolleyPhotograph}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              "You cannot purchase from two different countries with one purchase."
            }
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Would you like to checkout with your original photos OR start a
              new shopping cart
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CheckoutButton variant="dialog_checkout_button" />
            <Button onClick={onAddPhotographToNewTrolley}>New cart</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const renderShoppingCartButton = () => {
    return (
      <IconButton
        disabled={isSelecting || isDeselecting}
        className={classes.shopIcon}
        onClick={(e) => onSelectPhotoForBuy(photoId)}
      >
        <ShoppingCartIcon
          className={classes.icon}
          style={{ color: shoppingCartIconColor }}
        />
        {waitingForApiCallAddingPhotographToTrolley && renderSelectingLoader()}
      </IconButton>
    );
  };

  const renderPriceBox = () => {
    return (
      <Box
        onClick={() => {
          isMobile && openDigitalPricingDialog();
        }}
        className={
          galleryLowResPrice !== null
            ? classes.galleryLowResPriceBox
            : classes.priceBox
        }
      >
        <p className={classes.text}>
          {galleryLowResPrice !== null && (
            <Stack direction="row" useFlexGap flexWrap="wrap">
              <p className={classes.priceBoxWrapper}>
                <CurrencyValue
                  showCents={false}
                  value={galleryLowResPrice}
                  country={gallery.gallery_price.country}
                />
                /
                <CurrencyValue
                  value={price}
                  showCents={false}
                  country={gallery.gallery_price.country}
                />
              </p>
              {(showPhotographTimeTakenBox || isMobile) && (
                <p className={classes.lowHighResText}>Low/High Res</p>
              )}
            </Stack>
          )}
          {galleryLowResPrice == null && (
            <CurrencyValue
              value={price}
              showCents={false}
              country={gallery.gallery_price.country}
            />
          )}
        </p>
      </Box>
    );
  };

  return (
    <Box
      className={width > 300 ? classes.imgInnerCard : classes.imgInnerSmallCard}
    >
      {conditionsToRenderLowResPromptAreMet && (
        <LowHighResPhotoPrompt
          trolley={trolley}
          gallery={gallery}
          photoId={photoId}
          isUpdatingTrolley={isUpdatingTrolley}
          trolleyPhotograph={trolleyPhotograph}
          handleClose={hideLowHighResPhotoPrompt}
        />
      )}
      {tryingToAddCrossPhotographerTrolleyPhotograph &&
        tryRenderCrossPhotographerWarning()}
      <Box
        className={classes.innerLabelWrapper}
        width="100%"
        justifyContent="space-between"
      >
        {renderPriceBox()}
        {width > 300 && (
          <>
            {showIcons[`${gallery.id}-${photoId}`] === true && (
              <>
                {/* div className={classes.shareBox}>
                           <p className={classes.text}>26 days left</p>
                           <img src={HourGlassIcon} alt="" />
                           </div>*/}
                {/*x>
                           <IconButton className={classes.shopIcon}>
                           <ShareIcon className={classes.icon} />
                           </IconButton>
                           </Box>*/}
              </>
            )}
          </>
        )}
        <Box
          display="flex"
          flexDirection="column"
          onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
          onMouseLeave={() => setAnchorEl(null)}
        >
          {renderShoppingCartButton()}
        </Box>
        <Hidden smDown>
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={() => setAnchorEl(null)}
            disableRestoreFocus
          >
            <Box className={classes.smallbox}>Add a Photo to Trolley</Box>
          </Popover>
        </Hidden>
      </Box>
      {width < 300 && (
        <>
          {showIcons[`${gallery.id}-${photoId}`] === true && (
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              style={{ marginBottom: 10 }}
            >
              <div className={classes.shareBox}>
                <p className={classes.text}>26 days left</p>
                <img src={HourGlassIcon} alt="" />
              </div>
              <Box>
                <IconButton className={classes.shopIcon}>
                  <ShareIcon className={classes.icon} />
                </IconButton>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default InnerLabel;
