import { store } from "../index"

export const SET_TROLLEY_MESSAGE = "TROLLEY/SETTROLLEYMESSAGE"
export const SET_TROLLEY_ID = "TROLLEY/SETTROLLEYID"
export const CLEAR_TROLLEY_ID = "TROLLEY/CLEAER_TROLLEY_ID"

export const setMessage = (message) => ({
  type: SET_TROLLEY_MESSAGE,
  message,
})

export const setTrolleyID = (trolley_id) => ({
  type: SET_TROLLEY_ID,
  trolley_id: trolley_id,
})

export const clearTrolleyID = () => ({
  type: CLEAR_TROLLEY_ID,
})
