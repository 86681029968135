import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useIsMobile } from "../../actions/ui";
import { MainTrolleyLayout } from "./MainTrolleyLayout";
import { TrolleyProgressBar } from "./TrolleyProgressBar";
import LoadingSpinner from "../../views/components/LoadingSpinner";
import { authenticatedTrolleyList } from "../../v2_actions/authenticated_trolley";
import { trolleyList } from "../../v2_actions/trolley";

const useStyles = makeStyles((theme) => ({
  trolleyFormWrapper: {
    margin: "0px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      margin: "28px 32px",
    },
  },
}));

export const TrolleyLayout = ({ renderTrolleyForm, renderCarousel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { trolley_photograph_id } = useParams();
  const trolley_id = useSelector((state) => state.trolleyReducer.trolley_id)
  const authenticated_trolley = useSelector(() =>
    authenticatedTrolleyList.getObject(trolley_id),
  );
  const trolley_photographs = get(authenticated_trolley, "trolley_photographs");
  const [isAuthenticatedTrolleyLoading, setIsAuthenticatedTrolleyLoading] =
    useState(true);

  useEffect(() => {
    dispatch(authenticatedTrolleyList.ensureObjectLoaded(trolley_id))
  }, [trolley_id])

  useEffect(() => {
    const first_trolley_photograph = get(first(trolley_photographs), "id");
    const url_path = window.location.pathname;

    if (
      !trolley_photograph_id &&
      !isEmpty(authenticated_trolley) &&
      url_path !== "/customer/shipping_address"
    ) {
      navigate(`/customer/v2_trolley/${first_trolley_photograph}`);
      setIsAuthenticatedTrolleyLoading(false);
    } else if (!isEmpty(authenticated_trolley)) {
      setIsAuthenticatedTrolleyLoading(false);
    }
  }, [trolley_photograph_id, authenticated_trolley]);

  useEffect(() => {
    dispatch(trolleyList.invalidateList());
    dispatch(trolleyList.fetchListIfNeeded());
    dispatch(authenticatedTrolleyList.invalidateList());
    dispatch(authenticatedTrolleyList.invalidateAllObjects());
    dispatch(authenticatedTrolleyList.updatePaginationNumItemsPerPage(1));
    dispatch(authenticatedTrolleyList.fetchListIfNeeded());
    dispatch(authenticatedTrolleyList.ensureTrolleyLoaded());
  }, []);

  const renderLeftPane = () => {
    return (
      <>
        <Grid item xs={12} md={7} mt={8}>
          {renderCarousel()}
        </Grid>
        <Grid item md={0.5}>
          {isAuthenticatedTrolleyLoading && <LoadingSpinner />}
        </Grid>
      </>
    );
  };

  const renderRightPane = () => {
    return (
      <Grid item xs={12} sm={8} md={4.5}>
        <Box pt={4} className={classes.trolleyFormWrapper}>
          <TrolleyProgressBar
            trolley_photographs={trolley_photographs}
            trolley_photograph_id={trolley_photograph_id}
          />
          {renderTrolleyForm()}
        </Box>
      </Grid>
    );
  };

  return (
    <>
      {!isMobile && (
        <>
          <MainTrolleyLayout authenticated_trolley={authenticated_trolley}>
            {renderCarousel && renderLeftPane()}
            {renderRightPane()}
          </MainTrolleyLayout>
        </>
      )}
      {isMobile && (
        <>
          <MainTrolleyLayout
            authenticated_trolley={authenticated_trolley}
            active_trolley_photograph={trolley_photograph_id}
          >
            {renderRightPane()}
            {renderCarousel && renderLeftPane()}
          </MainTrolleyLayout>
        </>
      )}
    </>
  );
};
