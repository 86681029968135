import size from "lodash/size";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import debounce from "lodash/debounce";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Tombstone, TombstoneContainer } from "../Tombstone";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import BossfotosLoadingImg from "./BossfotosLoadingImg";
import FunctioningGalleryPhotograph from "./FunctioningGalleryPhotograph";
import { GalleryPasswordModal } from "../map/googlemap/GalleryPasswordModal";
import { ScrollToTopButton } from "../ScrollToTopButton";
import { galleryEventDirectAccessUrl } from "../../v2_actions/customer_gallery_event_direct_access_url";
import { makeLogger } from "../../utils/o11y/logger";
import { makeStyles } from "@material-ui/core";
import { photographList } from "../../v2_actions/customer_photograph";
import style from "../../views/galleryphotographs/styles";
import { trolleyList } from "../../v2_actions/trolley";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const defaultTheme = createTheme();
const DEFAULT_NUM_PHOTOS_TO_INCREMENT_BY_INFINITE_SCROLL = 10000;
const DEFAULT_MILLISECONDS_TO_REVEAL_HIGH_RES_PHOTOS = 15000;

const useStyles = makeStyles(style);

const logger = makeLogger({
  enabled: true,
  label: "GalleryPhotographsLayout",
  color: "maroon",
});

function isElementInViewport(el) {
  if (!el) {
    return false;
  }
  const rect = el.getBoundingClientRect();
  // trigger load when sentinel is approx 3 photos from bottom
  return (
    rect.top >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) + 700
  );
}
export default function GalleryPhotographsLayout({
  gallery,
  mobiClearFiltersButton,
  galleryPhotographFiltersArePristine,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { galleryId } = useParams();
  const containerRef = useRef();
  const loadMoreSentinel = useRef();
  const [galleryPasswordCorrect, setGalleryPasswordCorrect] = useState(false);
  const [hasPasswordBypassprivilege, setHasPasswordBypassprivilege] =
    useState(null);
  const trolley_id = useSelector((state) => state.trolleyReducer.trolley_id)
  const trolley = useSelector(() =>
    trolleyList.getObject(trolley_id),
  );
  const trolleyPhotographs = get(trolley, "trolley_photographs");
  const photographs = photographList.getVisibleObjects();
  const numPhotos = size(photographs);
  const photographsIsEmpty = isEmpty(photographs);
  const isTrolleyEmpty = isEmpty(trolleyPhotographs);
  const isLoadingPhotographs = photographList.isLoading();
  const [isAppendingContent, setIsAppendingContent] = useState(false); // determines whether additional photos are being appended
  const pagination = photographList.getPagination();
  const [revealHighResPhotos, setRevealHighResPhotos] = useState(false);
  const [shouldShowNoPhotosFound, setShouldShowNoPhotosFound] = useState(false);
  const allPagesLoaded = pagination.num_pages === pagination.current_page;

  const conditionsToRenderPhotosAreMet =
    false || isAppendingContent || Boolean(numPhotos && !isLoadingPhotographs);

  // logger.log("render()", {
  //   photographs,
  //   pagination,
  //   conditionsToRenderPhotosAreMet,
  //   allPagesLoaded,
  // });

  useEffect(() => {
    dispatch(trolleyList.ensureObjectLoaded(trolley_id))
  }, [trolley_id])

  const maybeLoadMore = () => {
    const { current_page, num_pages } = photographList.getPagination();
    const isLoadingCurrent = photographList.isLoading();
    logger.log("maybeLoadMore", {
      isLoadingCurrent,
      current_page,
      num_pages,
      photographs,
      photographsIsEmpty,
    });
    if (!isLoadingCurrent && current_page < num_pages) {
      setIsAppendingContent(true);
      const nextPage = current_page + 1;
      logger.log("maybeLoadMore Getting page ...", nextPage, {
        isLoadingCurrent,
        photographsIsEmpty,
      });
      dispatch(
        photographList.updateListPagination({
          ...pagination,
          page: nextPage,
        }),
      );
      if (!photographsIsEmpty) {
        logger.log("maybeLoadMore fetch");
        dispatch(photographList.fetchListIfNeeded({ forceUpdate: true }));
      }
    }
  };

  useLayoutEffect(
    function detectLoadMore() {
      let scrollHandler;
      const scrollRoot = document.querySelector(".gallery-scroll-container");
      if (scrollRoot) {
        scrollHandler = debounce((ev) => {
          if (isElementInViewport(loadMoreSentinel.current)) {
            // a bit of a hack since debounce captures the state
            // on load (stale). This indirectly triggers
            // maybeLoadMore() via the UI
            document.querySelector(".tombstone")?.click();
          }
        }, 100);
        scrollRoot.addEventListener("scroll", scrollHandler, true);
      }
      // No unmount of the listener - the component is not unmounted
      // return () => {
      //   scrollRoot.removeEventListener("scroll", scrollHandler);
      // };
    },
    [galleryId],
  );

  useEffect(() => {
    if (galleryId) {
      dispatch(photographList.clearListFilter());
      dispatch(photographList.updateListFilter({ gallery_db_id: galleryId }));
    }
  }, [galleryId]);

  useEffect(() => {
    if (
      !isLoadingPhotographs &&
      numPhotos === 0 &&
      !galleryPhotographFiltersArePristine
    ) {
      setShouldShowNoPhotosFound(true);
    } else {
      setShouldShowNoPhotosFound(false);
    }
  }, [isLoadingPhotographs, numPhotos]);

  useEffect(() => {
    logger.log("FX: bypass check", gallery);
    const gallery_event_id = get(gallery, "gallery_event");
    if (gallery_event_id) {
      setHasPasswordBypassprivilege(
        galleryEventDirectAccessUrl.getPasswordBypassStatusForGalleryEventGallery(
          gallery_event_id,
        ),
      );
    }
  }, [gallery]);

  const onSetSelectedImgId = useCallback((imageId) => {});

  const handleTombstoneClick = useCallback(() => {
    maybeLoadMore();
  });

  const handleViewGalleryButtonClicked = () => {
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const renderNumPhotosFiltered = () => {
    return (
      <p>
        {pagination?.num_items ?? 0} Photo
        {(pagination?.num_items ?? 0) > 1 && "s"} Filtered
      </p>
    );
  };
  const renderMobiFiltersButtonAndNumPhotosFiltered = () => (
    <Stack
      spacing={5}
      direction="row"
      sx={{ zIndex: 9 }}
      alignItems="center"
      justifyContent="space-between"
    >
      {Boolean(conditionsToRenderPhotosAreMet) && renderNumPhotosFiltered()}
      {mobiClearFiltersButton}
    </Stack>
  );

  const renderNoPhotosFound = () => {
    return (
      <Box
        sx={{
          mt: "100px",
          textAlign: "center",
        }}
      >
        <p>
          No Photos found.
          <br />
          Try clear the filters and search again or contact
          <a href="mailto:info@bossfotos.com">
            &nbsp; info@bossfotos.com &nbsp;
          </a>
          for assistance.
        </p>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <div ref={containerRef} />
      <main>
        <Container maxWidth="md">
          {isLoadingPhotographs && !isAppendingContent && (
            <Box
              sx={{
                pb: "80vh",
                textAlign: "center",
              }}
            >
              <BossfotosLoadingImg />
            </Box>
          )}
          {!galleryPhotographFiltersArePristine &&
            renderMobiFiltersButtonAndNumPhotosFiltered()}
          <Grid
            container
            spacing={4}
            className={classes.galleryPhotographsLayoutContainerGrid}
          >
            {conditionsToRenderPhotosAreMet &&
              map(photographs, (photograph, i) => (
                <FunctioningGalleryPhotograph
                  key={`photo-thumb-${i}`}
                  gallery={gallery}
                  showOverlays={true}
                  enableTrolleyIcon={true}
                  photograph={photograph}
                  isTrolleyEmpty={isTrolleyEmpty}
                  trolley={trolley}
                  trolleyPhotographs={trolleyPhotographs}
                  revealHighResPhotos={revealHighResPhotos}
                  setSelectedImgId={onSetSelectedImgId}
                />
              ))}
            <div
              class="loadmore"
              ref={loadMoreSentinel}
              style={{
                paddingLeft: "32px",
                height: "1px",
                width: "auto",
              }}
            ></div>
            {!allPagesLoaded && (
              <div
                style={{
                  height: "100px",
                  position: "relative",
                  width: "100%",
                  paddingLeft: "32px",
                }}
              >
                <TombstoneContainer onClick={handleTombstoneClick}>
                  <Tombstone isMobile={true} />
                </TombstoneContainer>
              </div>
            )}
          </Grid>
          {shouldShowNoPhotosFound && renderNoPhotosFound()}
          <ScrollToTopButton
            handleScrollToTop={handleViewGalleryButtonClicked}
          />
        </Container>
      </main>
      <>
        {get(gallery, "has_gallery_password") &&
          !galleryPasswordCorrect &&
          !hasPasswordBypassprivilege && (
            <Box display="flex" alignItems="center" flexDirection="column">
              <GalleryPasswordModal
                gallery={gallery}
                onCorrect={() => setGalleryPasswordCorrect(true)}
              />
            </Box>
          )}
      </>
    </ThemeProvider>
  );
}
