import React, { useState } from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import TopBar from "./topbar"
import SideBar from "../../layout/sidebar"
import styles from "./styles"
import { useIsMobile } from "../../actions/ui"
import { countryList } from "../../v2_actions/country"

const useStyles = makeStyles(styles)

const Terms = () => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const country_id = useSelector((state) => state.userReducer.userInfo?.country)
  const country = useSelector(() => countryList.getCountryCode(country_id))
  const [showSideBar, setShowSideBar] = useState(false)

  return (
    <div>
      <TopBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div
        className={!showSideBar ? classes.nonMennuWrapper : classes.menuWrapper}
        style={{ paddingTop: !isMobile ? 65 : 0 }}
      >
        <div className={classes.content}>
          {country === "za" ? (
            <div className={classes.pharagraph}>
              <p>BOSSFOTOS SITE TERMS OF SERVICE</p>
              <p>Last Updated: February 2021</p>
              <p>PLEASE READ THESE TERMS OF USE CAREFULLY.</p>
              <p>
                BY ACCESSING OR USING ANY BossFotos WEBSITE OR MOBILE
                APPLICATION (OR ANY CONTENT, PRODUCT, SERVICE, OR FEATURE
                AVAILABLE THROUGH THE WEBSITE OR MOBILE APPLICATION, INCLUDING
                THE EMBEDDED VIEWER) (COLLECTIVELY, THE "SITE"), YOU AGREE TO
                ABIDE BY AND BE BOUND BY THE TERMS DESCRIBED HEREIN AND BY ALL
                TERMS, POLICIES AND GUIDELINES INCORPORATED BY REFERENCE, AS
                WELL AS ANY ADDITIONAL TERMS AND RESTRICTIONS PRESENTED IN
                RELATION TO SPECIFIC CONTENT OR A SPECIFIC PRODUCT, SERVICE OR
                FEATURE (COLLECTIVELY, THE "SITE TERMS"). IF YOU DO NOT AGREE TO
                ALL OF THESE TERMS, DO NOT USE THE SITE.
              </p>
              <p>
                These Site Terms are not intended to alter the terms or
                conditions of any content license agreement you may have with
                BossFotos, its subsidiaries or affiliates, and to the extent of
                any conflict, the terms of your content license agreement will
                prevail. By using the Site, you represent and warrant that you
                are over the age of 18 and are lawfully able to accept these
                Site Terms. If you are using the Site on behalf of any entity,
                you further represent and warrant that you are authorized to
                accept these Site Terms on such entity's behalf, and that such
                entity agrees to indemnify BossFotos for violations of these
                Site Terms.{" "}
              </p>
              <p>Privacy Policy</p>
              <p>
                BossFotos is committed to protecting your privacy. Please refer
                to our Privacy Policy for information on how we collect, use and
                disclose personal information
              </p>
              <p>Ownership of the Site and its Contents</p>
              <p>
                The Site is owned by BossFotos. Unless otherwise indicated, all
                of the content featured or displayed on the Site, including, but
                not limited to, text, graphics, data, photographic images,
                moving images, sound, illustrations, software, and the selection
                and arrangement thereof ("BossFotos Content"), is owned by
                BossFotos, its licensors, or its third-party image partners. All
                elements of the Site, including the BossFotos Content, are
                protected by copyright, trade dress, moral rights, trademark and
                other laws relating to the protection of intellectual property.
                We also appreciate any feedback or other suggestions about our
                services that you may submit to us, but you understand that we
                may use such feedback or suggestions without any obligation to
                compensate you for them.{" "}
              </p>
              <p>Use of the Site</p>
              <p>
                The Site and the BossFotos Content are intended for customers of
                BossFotos. You may not use the Site or the BossFotos Content for
                any purpose not related to your business with BossFotos. You are
                specifically prohibited from:{" "}
              </p>
              <p>
                a.downloading, copying, or re-transmitting any or all of the
                Site or the BossFotos Content without, or in violation of, a
                written license or agreement with BossFotos;{" "}
              </p>
              <p>
                b. using any data mining, robots or similar data gathering or
                extraction methods;{" "}
              </p>
              <p>
                c. manipulating or otherwise displaying the Site or the
                BossFotos Content by using framing or similar navigational
                technology;{" "}
              </p>
              <p>
                d. registering, subscribing, unsubscribing, or attempting to
                register, subscribe, or unsubscribe any party for any BossFotos
                product or service if you are not expressly authorized by such
                party to do so;{" "}
              </p>
              <p>
                e. reverse engineering, altering or modifying any part of the
                Site or the BossFotos Content;{" "}
              </p>
              <p>
                f. circumventing, disabling or otherwise interfering with
                security-related features of the Site or any system resources,
                services or networks connected to or accessible through the
                Site;{" "}
              </p>
              <p>
                g. selling, licensing, leasing, or in any way commercializing
                the Site or the BossFotos Content without specific written
                authorization from BossFotos; and{" "}
              </p>
              <p>
                h. using the Site or the BossFotos Content other than for its
                intended purpose. Such unauthorized use may also violate
                applicable laws including without limitation copyright and
                trademark laws, the laws of privacy and publicity, and
                applicable communications regulations and statutes. You
                represent and warrant that you will comply with all applicable
                laws and regulations, including, without limitation, those
                relating to the Internet, data, e-mail, privacy, and the
                transmission of technical data exported from South Africa or the
                country in which you reside.{" "}
              </p>
              <p>Copyright Infringement Policy</p>
              <p>
                In accordance with the applicable laws, BossFotos has adopted a
                policy of terminating, in appropriate circumstances and at
                BossFotos's sole discretion, account holders who infringe the
                intellectual property rights of BossFotos or any third party.{" "}
              </p>
              <p>Copyright Complaints</p>
              <p>
                If you believe that any material on the Site infringes upon any
                copyright that you own or control, you may notify BossFotos of
                such infringement:{" "}
              </p>
              <p>Trademark</p>
              <p>
                BossFotos logos and any other product or service name or slogan
                contained in the Site are trademarks of BossFotos and its
                suppliers or licensors, and may not be copied, imitated or used,
                in whole or in part, without the prior written permission of
                BossFotos or the applicable trademark holder. You may not use
                metatags or any other "hidden text" utilizing "BossFotos" or any
                other name, trademark or product or service name of BossFotos
                without our prior written permission. In addition, the look and
                feel of the Site, including all page headers, custom graphics,
                button icons and scripts, is the service mark, trademark and/or
                trade dress of BossFotos and may not be copied, imitated or
                used, in whole or in part, without our prior written permission.
                All other trademarks, registered trademarks, product names and
                company names or logos mentioned in the Site are the property of
                their respective owners. Reference to ny products, services,
                processes or other information, by trade name, trademark,
                manufacturer, supplier or otherwise does not constitute or imply
                endorsement, sponsorship or recommendation thereof by us.{" "}
              </p>
              <p>Links</p>
              <p>
                You may not use a BossFotos logo or other proprietary graphic of
                BossFotos to link to the Site without the express written
                permission of BossFotos. Further, you may not frame any
                BossFotos trademark, logo or other proprietary information,
                including the BossFotos Content, without BossFotos' express
                written consent. BossFotos makes no claim or representation
                regarding, and accepts no responsibility for, directly or
                indirectly, the quality, content, nature or reliability of
                third-party websites accessible by hyperlink from the Site, or
                websites linking to the Site. Such sites are not under the
                control of BossFotos and BossFotos is not responsible for the
                contents of any linked site or any link contained in a linked
                site, or any review, changes or updates to such sites. BossFotos
                provides these links to you only as a convenience, and the
                inclusion of any link does not imply affiliation, endorsement or
                adoption by BossFotos of any site or any information contained
                therein. When you leave the Site, you should be aware that our
                terms and policies no longer govern. You should review the
                applicable terms and policies, including privacy and data
                gathering practices, of any site to which you navigate from the
                Site. Your participation, correspondence or business dealings
                with any third party found on or through the Site, regarding the
                payment and delivery of related goods or services, and any other
                terms, conditions, warranties or representations associated with
                such dealings, are solely between you and such third party. You
                agree that BossFotos shall not be responsible or liable for any
                loss, damage or other matters of any sort incurred as the result
                of any such dealings.{" "}
              </p>
              <p>Embedded Viewer</p>
              <p>
                Where enabled, you may embed BossFotos Content on a website,
                blog or social media platform using the embedded viewer (the
                “Embedded Viewer”). Not all BossFotos Content will be available
                for embedded use, and availability may change without notice.
                BossFotos reserves the right in its sole discretion to remove
                BossFotos Content from the Embedded Viewer. Upon request, you
                agree to take prompt action to stop using the Embedded Viewer
                and/or BossFotos Content. You may only use embedded BossFotos
                Content for editorial purposes (meaning relating to events that
                are newsworthy or of public interest). Embedded BossFotos
                Content may not be used: (a) for any commercial purpose (for
                example, in advertising, promotions or merchandising) or to
                suggest endorsement or sponsorship; (b) in violation of any
                stated restriction; (c) in a defamatory, pornographic or
                otherwise unlawful manner; or (d) outside of the context of the
                Embedded Viewer. BossFotos (or third parties acting on its
                behalf) may collect data related to use of the Embedded Viewer
                and embedded BossFotos Content, and reserves the right to place
                advertisements in the Embedded Viewer or otherwise monetize its
                use without any compensation to you.{" "}
              </p>
              <p>Uploading of Material</p>
              <p>
                Uploading Media Material All Media Material uploaded or
                otherwise submitted to the Site is the sole responsibility of
                the Seller from which such Media Material originates and you
                acknowledge and agree that you, and not the Company and/or the
                Site, are entirely responsible for all Media Material that you
                upload or otherwise submit to the Site. The Company and/or the
                Site does not control user submitted Media Material and, as
                such, does not guarantee the accuracy, integrity or quality of
                such Media Material. As a condition of use, you promise not to
                use the Site for any purpose that is unlawful or prohibited by
                these Terms and Conditions, or any other purpose not reasonably
                intended by the Site. By way of example, and not as a
                limitation, you agree not to use the Services:{" "}
              </p>
              <p>
                a. To upload or post copyrighted Media Material which doesn’t
                belong to you and where the copyrighted for the Media Material
                is not owned wholly and solely by you;{" "}
              </p>
              <p>
                b. To upload, post, transmit, promote or sell Media Material of
                another person;{" "}
              </p>
              <p>
                c. To abuse, harass, threaten, impersonate or intimidate any
                person;{" "}
              </p>
              <p>
                d. To upload, post or transmit, or cause to be uploaded, posted
                or transmitted, any Media Material that is libellous,
                defamatory, obscene, pornographic, abusive, offensive, profane,
                is deemed by the Site or the Company as inappropriate or that
                infringes any copyright or other right of any person;{" "}
              </p>
              <p>
                e. For any purpose (including posting or viewing Media Material)
                that is not permitted under the laws of the jurisdiction where
                you use the Site;{" "}
              </p>
              <p>
                f. To upload, post or transmit, or cause to be uploaded, posted
                or transmitted, any communication or solicitation designed or
                intended to obtain password, account, or private information
                from any user;{" "}
              </p>
              <p>
                g. To create, upload or transmit unwanted ‘spam’ to any person
                or any URL;{" "}
              </p>
              <p>h. To create or hold multiple accounts; </p>
              <p>
                i. You will not use any robot, spider, scraper or other
                automated means to access the Site for any purpose without the
                Company express written permission. Additionally, you agree that
                you will not: (i) take any action that imposes, or may impose in
                our sole discretion an unreasonable or disproportionately large
                load on our infrastructure; (ii) interfere or attempt to
                interfere with the proper working of the Site or any activities
                conducted on the Site; or (iii) bypass any measures we may use
                to prevent or restrict access to the Site;{" "}
              </p>
              <p>
                j. To sell or otherwise transfer your profile. By uploading
                Media Material to the Site the Seller has accepted these Terms
                and Conditions and agrees to the following conditions:{" "}
              </p>
              <p>
                k. The Seller owns the exclusive copyright to Media Material and
                the Media Material is original and owned by the Seller;{" "}
              </p>
              <p>
                l. The Seller is offering the Buyer, the Site, the host and the
                Company a non-exclusive, transferable, fully paid world-wide
                license (with the right to sublicense) to the Sellers Media
                Material;{" "}
              </p>
              <p>
                m. In exchange for the non-exclusive license outlined above the
                Buyer will pay the Seller the nominated fee for the Media
                Material less any costs from the host or other parties. The
                license fee terms are laid out in "License Fee and Payment
                Terms";{" "}
              </p>
              <p>
                n. The Site and/or the Company may choose to promote Media
                Material on the Site from time to time and as such may offer
                Sellers a discount on the Media Material offered on the Site.
                The discount due to sales and/or promotion activity will be
                passed on to the Seller as an associated cost of sale as laid
                out in License Fee and Payment Terms;{" "}
              </p>
              <p>
                o. The host is not responsible for any loss or damage to your
                Media Material caused by technical, human, natural or any other
                cause;{" "}
              </p>
              <p>
                p. The host is acting solely to facilitate the Seller to sell
                the Media Material to the Buyer. Any disputes that the Seller or
                buyer have should be resolved by the two parties. The Site will
                not enter into any disputes and the Site will play no part in
                disputes between the Seller or buyer other than providing the
                Seller and buyers email addresses to both parties;{" "}
              </p>
              <p>
                q. Neither the host, the Site or the Company is not responsible
                or liable for the copyright of your Media Material. If a buyer
                or any other party, including anyone that viewed your material
                through the Site, breaches your copyright the host is not
                responsible in any way;{" "}
              </p>
              <p>
                r. If other people or places are shown in your Media Material
                you must gain their written acceptance to sell and distribute
                that information. You are solely responsible for gaining and
                exclusively owning the copyright for your Media Material;{" "}
              </p>
              <p>
                s. The Company reserves the right to remove Media Material from
                the Site or remove the registration of any seller uploading
                Media Material that breeches any of these Terms and Conditions
                from the Site;{" "}
              </p>
              <p>
                t. You are the owner of all rights, including all copyrights in
                and to all Media Material you upload or submit to the Site;{" "}
              </p>
              <p>
                u. You have the full and complete right to enter into this
                agreement and to grant to the Company and the Site the rights in
                the Content herein granted, and that no further permissions are
                required from, nor payments required to be made to any other
                person in connection with the use by the Site of the Media
                Material as contemplated herein;{" "}
              </p>
              <p>
                v. The Content does not defame any person and does not infringe
                upon the copyright, moral rights, publicity rights, privacy
                rights or any other right of any person, or violate any law or
                judicial or governmental order;{" "}
              </p>
              <p>
                w.shall not have any right to terminate the permissions granted
                herein, nor to seek, obtain, or enforce any injunctive or other
                equitable relief against the Site or the Company, all of which
                such rights are hereby expressly and irrevocably waived by you
                in favor of the Site and the Company.{" "}
              </p>

              <p>Forums & Other Interactive Services or Areas</p>
              <p>
                The Site may include discussion forums or other interactive
                areas or services, including blogs, chat rooms, bulletin boards,
                message boards, online hosting or storage services, or other
                areas or services in which you or third parties create, post or
                store any content, messages, comments, materials or other items
                on the Site ("Interactive Areas"). You are solely responsible
                for your use of such Interactive Areas and use them at your own
                risk. By using any Interactive Areas, you agree not to post,
                upload, transmit, distribute, store, create or otherwise publish
                through the Site any of the following:{" "}
              </p>
              <p>
                a. Any message, comment, data, information, text, music, sound,
                photos, graphics, code or other material ("User Content") that
                is unlawful, libelous, defamatory, obscene, pornographic,
                harmful to minors, indecent, lewd, suggestive, harassing,
                threatening, invasive of privacy or publicity rights, abusive,
                inflammatory, fraudulent or otherwise objectionable;{" "}
              </p>
              <p>
                b. User Content that would constitute, encourage or provide
                instructions for a criminal offense;{" "}
              </p>
              <p>
                c. User Content that may infringe any patent, trademark, trade
                secret, copyright or other intellectual property or contract
                right of any party. By posting any User Content, you represent
                and warrant that you have the lawful right to transmit,
                distribute and reproduce such User Content;{" "}
              </p>
              <p>
                d. User Content that impersonates any person or entity or
                otherwise misrepresents your affiliation with a person or
                entity;{" "}
              </p>
              <p>
                e. Unsolicited promotions, political campaigning, advertising,
                junk mail, spam, chain letters, pyramid schemes or
                solicitations;{" "}
              </p>
              <p>
                f. Private information of any third party, including, without
                limitation, addresses, phone numbers, email addresses and credit
                card numbers;{" "}
              </p>
              <p>
                g. Viruses, corrupted data or other harmful, disruptive or
                destructive files; and h. User Content that, in the sole
                judgment of BossFotos, is objectionable or which restricts or
                inhibits any other person from using or enjoying the Interactive
                Areas or the Site, or which adversely affects the availability
                of its resources to other users (e.g., excessive shouting, use
                of all capital letters, or flooding continuous posting of
                repetitive text), or which may expose BossFotos or its users to
                any harm or liability of any type. Further, you agree not to
                delete or revise any User Content posted by any third party.
                BossFotos takes no responsibility and assumes no liability for
                any User Content posted, stored or uploaded by you or any third
                party, or for any loss or damage thereto, nor is BossFotos
                liable for any mistakes, defamation, slander, libel, omissions,
                falsehoods, obscenity, pornography or profanity you may
                encounter. Your use of Interactive Areas is at your own risk. As
                a provider of interactive services, BossFotos is not liable for
                any statements, representations or User Content provided by its
                users in any public forum, personal home page or other
                Interactive Area. Although BossFotos has no obligation to
                screen, edit or monitor any of the User Content posted in any
                Interactive Area, BossFotos reserves the right, and has absolute
                discretion, to remove, screen or edit any User Content posted or
                stored on the Site at any time and for any reason without
                notice. You are solely responsible for creating backup copies of
                and replacing any User Content you post or store on the Site at
                your sole cost and expense. Any use of the Interactive Areas or
                other portions of the Site in violation of the foregoing
                violates these Site Terms and may result in, among other things,
                termination or suspension of your rights to use the Interactive
                Areas and/or the Site. You acknowledge and agree that BossFotos
                may access, use or disclose any information about you or your
                use of this Site, including without limitation any User Content
                to comply with the law or any legal process; protect and defend
                the rights or property of BossFotos; or to protect the safety of
                our company, employees, customers or the public. If you post
                User Content to the Site, unless we indicate otherwise, you
                grant BossFotos and its affiliates a nonexclusive, royalty-free,
                perpetual, irrevocable and fully sublicensable right to use,
                reproduce, modify, adapt, publish, translate, create derivative
                works from, distribute, perform and display such User Content
                throughout the world in any media. You grant BossFotos and its
                affiliates and sublicensees the right to use the name that you
                submit in connection with such content, if they choose. You
                represent and warrant that (i) you own and control all of the
                rights to the User Content that you post or you otherwise have
                the right to post such User Content to the Site; (ii) the User
                Content is accurate and not misleading; and (iii) use and
                posting of the User Content you supply does not violate these
                Site Terms and will not violate any rights of or cause injury to
                any person or entity.{" "}
              </p>
              <p>Registration Data and Account Security</p>
              <p>In consideration of your use of the Site, you agree to: </p>
              <p>
                a. provide accurate, current and complete information about you
                as may be prompted by any registration forms on the Site
                ("Registration Data");{" "}
              </p>
              <p>
                b. maintain and promptly update the Registration Data, and any
                other information you provide to Company, to keep it accurate,
                current and complete;{" "}
              </p>
              <p>
                c.maintain the security of your password and identification;{" "}
              </p>
              <p>
                d. notify BossFotos immediately of any unauthorized use of your
                account or other breach of security;{" "}
              </p>
              <p>
                e. accept all responsibility for any and all activities that
                occur under your account, including but not limited to, all
                applicable taxes and any applicable third-party fees (including
                but not limited to credit card fees, foreign exchange fees and
                cross border fees); and{" "}
              </p>
              <p>
                f. accept all risks of unauthorized access to the Registration
                Data and any other information you provide to Company.{" "}
              </p>
              <p>Indemnification</p>
              <p>
                You agree to defend, indemnify and hold harmless BossFotos, its
                subsidiaries, affiliates, licensors, employees, agents, third
                party information providers and independent contractors against
                any claims, damages, costs, liabilities and expenses (including,
                but not limited to, reasonable attorneys' fees) arising out of
                or related to any User Content that you post, store or otherwise
                transmit on or through the Site, your conduct, your use or
                inability to use the Site, your breach or alleged breach of the
                Site Terms or of any representation or warranty contained
                herein, your unauthorized use of the BossFotos Content, or your
                violation of any rights of another.{" "}
              </p>
              <p>Disclaimer</p>
              <p>
                THE SITE, INCLUDING WITHOUT LIMITATION THE EMBEDDED VIEWER AND
                THE BossFotos CONTENT, ARE PROVIDED "AS IS" AND BossFotos AND
                ITS DIRECTORS, EMPLOYEES, CONTENT PROVIDERS, AGENTS AND
                AFFILIATES EXCLUDE, TO THE FULLEST EXTENT PERMITTED BY
                APPLICABLE LAW, ANY WARRANTY, EXPRESS OR IMPLIED, INCLUDING,
                WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE.
                BossFotos WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING
                FROM THE USE OF THE SITE OR THE BossFotos CONTENT, OR THE
                UNAVAILABILITY OF THE SAME, INCLUDING, BUT NOT LIMITED TO LOST
                PROFITS, AND DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND
                CONSEQUENTIAL DAMAGES. THE FUNCTIONS EMBODIED ON OR IN THE
                MATERIALS OF THIS SITE ARE NOT WARRANTED TO BE UNINTERRUPTED OR
                WITHOUT ERROR. YOU, NOT BossFotos, ASSUME THE ENTIRE COST OF ALL
                NECESSARY SERVICING, REPAIR OR CORRECTION DUE TO YOUR USE OF
                THIS SITE OR THE BossFotos CONTENT. WE MAKE NO WARRANTY THAT THE
                SITE OR THE BossFotos CONTENT IS FREE FROM INFECTION BY VIRUSES
                OR ANYTHING ELSE THAT HAS CONTAMINATING OR DESTRUCTIVE
                PROPERTIES. BossFotos uses reasonable efforts to ensure the
                accuracy, correctness and reliability of the BossFotos Content,
                but we make no representations or warranties as to the BossFotos
                Content's accuracy, correctness or reliability. BossFotos offers
                a search feature within the Site. BossFotos explicitly disclaims
                any responsibility for the content or availability of
                information contained in our search index or directory.
                BossFotos also disclaims any responsibility for the completeness
                or accuracy of any directory or search result.{" "}
              </p>
              <p>Limitation of Liability</p>
              <p>
                IN NO EVENT SHALL BossFotos, ITS DIRECTORS, MEMBERS, EMPLOYEES
                OR AGENTS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR
                CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND,
                INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR
                LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING
                BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR
                IN ANY WAY CONNECTED WITH THE USE OF THE SITE, THE SERVICES, THE
                BossFotos CONTENT OR THE MATERIALS CONTAINED IN OR ACCESSED
                THROUGH THE SITE, INCLUDING WITHOUT LIMITATION ANY DAMAGES
                CAUSED BY OR RESULTING FROM RELIANCE BY USER ON ANY INFORMATION
                OBTAINED FROM BossFotos, OR THAT RESULT FROM MISTAKES,
                OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS,
                DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY
                FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF
                GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED
                ACCESS TO BossFotos' RECORDS, PROGRAMS OR SERVICES. IN NO EVENT
                SHALL THE AGGREGATE LIABILITY OF BossFotos, WHETHER IN CONTRACT,
                WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR
                IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY,
                ARISING OUT OF OR RELATING TO THE USE OF THE SITE EXCEED ANY
                COMPENSATION YOU PAY, IF ANY, TO BossFotos FOR ACCESS TO OR USE
                OF THE SITE.{" "}
              </p>
              <p>Applicable Law and Venue</p>
              <p>
                Any dispute relating in any way to your use of the Site or the
                BossFotos Content shall be submitted to confidential arbitration
                in Cape Town, South Africa. The arbitrator's award shall be
                binding and may be entered as a judgment in any court of
                competent jurisdiction. To the fullest extent permitted by
                applicable law, no arbitration under this Agreement shall be
                joined to an arbitration involving any other party subject to
                the Site Terms.{" "}
              </p>
              <p>Termination</p>
              <p>
                Notwithstanding any of these Site Terms, BossFotos reserves the
                right, without notice and in its sole discretion, to terminate
                your account and/or to block your use of the Site.{" "}
              </p>
              <p>Miscellaneous Provisions</p>
              <p>
                Any waiver of any provision of the Site Terms will be effective
                only if in writing and signed by BossFotos. If any clause in
                these Site Terms is found to be unenforceable, wherever possible
                this will not affect any other clause and each will remain in
                full force and effect. Any rights not expressly granted herein
                are reserved.{" "}
              </p>
              <p>Changes to Site Terms</p>
              <p>
                BossFotos reserves the right to change any of the terms and
                conditions contained in the Site Terms or any policy or
                guideline of the Site, at any time and in its sole discretion.
                When we make changes, we will revise the "last updated" date at
                the top of the Site Terms. Any changes will be effective
                immediately upon posting on the Site. Your continued use of the
                Site following the posting of changes will constitute your
                acceptance of such changes. We encourage you to review the Site
                Terms whenever you visit one
              </p>
            </div>
          ) : (
            <div className={classes.pharagraph}>
              <p>BOSSFOTOS SITE TERMS OF SERVICE</p>
              <p>Last Updated: February 2021</p>
              <p>PLEASE READ THESE TERMS OF USE CAREFULLY.</p>
              <p>
                BY ACCESSING OR USING ANY BossFotos WEBSITE OR MOBILE
                APPLICATION (OR ANY CONTENT, PRODUCT, SERVICE, OR FEATURE
                AVAILABLE THROUGH THE WEBSITE OR MOBILE APPLICATION, INCLUDING
                THE EMBEDDED VIEWER) (COLLECTIVELY, THE "SITE"), YOU AGREE TO
                ABIDE BY AND BE BOUND BY THE TERMS DESCRIBED HEREIN AND BY ALL
                TERMS, POLICIES AND GUIDELINES INCORPORATED BY REFERENCE, AS
                WELL AS ANY ADDITIONAL TERMS AND RESTRICTIONS PRESENTED IN
                RELATION TO SPECIFIC CONTENT OR A SPECIFIC PRODUCT, SERVICE OR
                FEATURE (COLLECTIVELY, THE "SITE TERMS"). IF YOU DO NOT AGREE TO
                ALL OF THESE TERMS, DO NOT USE THE SITE.{" "}
              </p>
              <p>
                These Site Terms are not intended to alter the terms or
                conditions of any content license agreement you may have with
                BossFotos, its subsidiaries or affiliates, and to the extent of
                any conflict, the terms of your content license agreement will
                prevail. By using the Site, you represent and warrant that you
                are over the age of 18 and are lawfully able to accept these
                Site Terms. If you are using the Site on behalf of any entity,
                you further represent and warrant that you are authorized to
                accept these Site Terms on such entity's behalf, and that such
                entity agrees to indemnify BossFotos for violations of these
                Site Terms.{" "}
              </p>
              <p>Privacy Policy</p>
              <p>
                BossFotos is committed to protecting your privacy. Please refer
                to our Privacy Policy for information on how we collect, use and
                disclose personal information
              </p>
              <p>Ownership of the Site and its Contents</p>
              <p>
                The Site is owned by BossFotos. Unless otherwise indicated, all
                of the content featured or displayed on the Site, including, but
                not limited to, text, graphics, data, photographic images,
                moving images, sound, illustrations, software, and the selection
                and arrangement thereof ("BossFotos Content"), is owned by
                BossFotos, its licensors, or its third-party image partners. All
                elements of the Site, including the BossFotos Content, are
                protected by copyright, trade dress, moral rights, trademark and
                other laws relating to the protection of intellectual property.
                We also appreciate any feedback or other suggestions about our
                services that you may submit to us, but you understand that we
                may use such feedback or suggestions without any obligation to
                compensate you for them.{" "}
              </p>
              <p>Use of the Site</p>
              <p>
                The Site and the BossFotos Content are intended for customers of
                BossFotos. You may not use the Site or the BossFotos Content for
                any purpose not related to your business with BossFotos. You are
                specifically prohibited from:{" "}
              </p>
              <p>
                a.downloading, copying, or re-transmitting any or all of the
                Site or the BossFotos Content without, or in violation of, a
                written license or agreement with BossFotos;{" "}
              </p>
              <p>
                b. using any data mining, robots or similar data gathering or
                extraction methods;{" "}
              </p>
              <p>
                c. manipulating or otherwise displaying the Site or the
                BossFotos Content by using framing or similar navigational
                technology;{" "}
              </p>
              <p>
                d. registering, subscribing, unsubscribing, or attempting to
                register, subscribe, or unsubscribe any party for any BossFotos
                product or service if you are not expressly authorized by such
                party to do so;{" "}
              </p>
              <p>
                e. reverse engineering, altering or modifying any part of the
                Site or the BossFotos Content;{" "}
              </p>
              <p>
                f. circumventing, disabling or otherwise interfering with
                security-related features of the Site or any system resources,
                services or networks connected to or accessible through the
                Site;{" "}
              </p>
              <p>
                g. selling, licensing, leasing, or in any way commercializing
                the Site or the BossFotos Content without specific written
                authorization from BossFotos; and{" "}
              </p>
              <p>
                h. using the Site or the BossFotos Content other than for its
                intended purpose. Such unauthorized use may also violate
                applicable laws including without limitation copyright and
                trademark laws, the laws of privacy and publicity, and
                applicable communications regulations and statutes. You
                represent and warrant that you will comply with all applicable
                laws and regulations, including, without limitation, those
                relating to the Internet, data, e-mail, privacy, and the
                transmission of technical data exported from Australia or the
                country in which you reside.{" "}
              </p>
              <p>Copyright Infringement Policy</p>
              <p>
                In accordance with the applicable laws, BossFotos has adopted a
                policy of terminating, in appropriate circumstances and at
                BossFotos's sole discretion, account holders who infringe the
                intellectual property rights of BossFotos or any third party.{" "}
              </p>
              <p>Copyright Complaints</p>
              <p>
                If you believe that any material on the Site infringes upon any
                copyright that you own or control, you may notify BossFotos of
                such infringement:{" "}
              </p>
              <p>Refunds Policy</p>
              <p>
                A full refund is available for seven days to customers who have
                purchased photos
              </p>
              <p>Trademark</p>
              <p>
                BossFotos logos and any other product or service name or slogan
                contained in the Site are trademarks of BossFotos and its
                suppliers or licensors, and may not be copied, imitated or used,
                in whole or in part, without the prior written permission of
                BossFotos or the applicable trademark holder. You may not use
                metatags or any other "hidden text" utilizing "BossFotos" or any
                other name, trademark or product or service name of BossFotos
                without our prior written permission. In addition, the look and
                feel of the Site, including all page headers, custom graphics,
                button icons and scripts, is the service mark, trademark and/or
                trade dress of BossFotos and may not be copied, imitated or
                used, in whole or in part, without our prior written permission.
                All other trademarks, registered trademarks, product names and
                company names or logos mentioned in the Site are the property of
                their respective owners. Reference to ny products, services,
                processes or other information, by trade name, trademark,
                manufacturer, supplier or otherwise does not constitute or imply
                endorsement, sponsorship or recommendation thereof by us.{" "}
              </p>
              <p>Links</p>
              <p>
                You may not use a BossFotos logo or other proprietary graphic of
                BossFotos to link to the Site without the express written
                permission of BossFotos. Further, you may not frame any
                BossFotos trademark, logo or other proprietary information,
                including the BossFotos Content, without BossFotos' express
                written consent. BossFotos makes no claim or representation
                regarding, and accepts no responsibility for, directly or
                indirectly, the quality, content, nature or reliability of
                third-party websites accessible by hyperlink from the Site, or
                websites linking to the Site. Such sites are not under the
                control of BossFotos and BossFotos is not responsible for the
                contents of any linked site or any link contained in a linked
                site, or any review, changes or updates to such sites. BossFotos
                provides these links to you only as a convenience, and the
                inclusion of any link does not imply affiliation, endorsement or
                adoption by BossFotos of any site or any information contained
                therein. When you leave the Site, you should be aware that our
                terms and policies no longer govern. You should review the
                applicable terms and policies, including privacy and data
                gathering practices, of any site to which you navigate from the
                Site. Your participation, correspondence or business dealings
                with any third party found on or through the Site, regarding the
                payment and delivery of related goods or services, and any other
                terms, conditions, warranties or representations associated with
                such dealings, are solely between you and such third party. You
                agree that BossFotos shall not be responsible or liable for any
                loss, damage or other matters of any sort incurred as the result
                of any such dealings.{" "}
              </p>
              <p>Embedded Viewer</p>
              <p>
                Where enabled, you may embed BossFotos Content on a website,
                blog or social media platform using the embedded viewer (the
                “Embedded Viewer”). Not all BossFotos Content will be available
                for embedded use, and availability may change without notice.
                BossFotos reserves the right in its sole discretion to remove
                BossFotos Content from the Embedded Viewer. Upon request, you
                agree to take prompt action to stop using the Embedded Viewer
                and/or BossFotos Content. You may only use embedded BossFotos
                Content for editorial purposes (meaning relating to events that
                are newsworthy or of public interest). Embedded BossFotos
                Content may not be used: (a) for any commercial purpose (for
                example, in advertising, promotions or merchandising) or to
                suggest endorsement or sponsorship; (b) in violation of any
                stated restriction; (c) in a defamatory, pornographic or
                otherwise unlawful manner; or (d) outside of the context of the
                Embedded Viewer. BossFotos (or third parties acting on its
                behalf) may collect data related to use of the Embedded Viewer
                and embedded BossFotos Content, and reserves the right to place
                advertisements in the Embedded Viewer or otherwise monetize its
                use without any compensation to you.{" "}
              </p>
              <p>Uploading of Material</p>
              <p>
                Uploading Media Material All Media Material uploaded or
                otherwise submitted to the Site is the sole responsibility of
                the Seller from which such Media Material originates and you
                acknowledge and agree that you, and not the Company and/or the
                Site, are entirely responsible for all Media Material that you
                upload or otherwise submit to the Site. The Company and/or the
                Site does not control user submitted Media Material and, as
                such, does not guarantee the accuracy, integrity or quality of
                such Media Material. As a condition of use, you promise not to
                use the Site for any purpose that is unlawful or prohibited by
                these Terms and Conditions, or any other purpose not reasonably
                intended by the Site. By way of example, and not as a
                limitation, you agree not to use the Services:{" "}
              </p>
              <p>
                a. To upload or post copyrighted Media Material which doesn’t
                belong to you and where the copyrighted for the Media Material
                is not owned wholly and solely by you;{" "}
              </p>
              <p>
                b. To upload, post, transmit, promote or sell Media Material of
                another person;{" "}
              </p>
              <p>
                c. To abuse, harass, threaten, impersonate or intimidate any
                person;{" "}
              </p>
              <p>
                d. To upload, post or transmit, or cause to be uploaded, posted
                or transmitted, any Media Material that is libellous,
                defamatory, obscene, pornographic, abusive, offensive, profane,
                is deemed by the Site or the Company as inappropriate or that
                infringes any copyright or other right of any person;{" "}
              </p>
              <p>
                e. For any purpose (including posting or viewing Media Material)
                that is not permitted under the laws of the jurisdiction where
                you use the Site;{" "}
              </p>
              <p>
                f. To upload, post or transmit, or cause to be uploaded, posted
                or transmitted, any communication or solicitation designed or
                intended to obtain password, account, or private information
                from any user;{" "}
              </p>
              <p>
                g. To create, upload or transmit unwanted ‘spam’ to any person
                or any URL;{" "}
              </p>
              <p>h. To create or hold multiple accounts; </p>
              <p>
                i. You will not use any robot, spider, scraper or other
                automated means to access the Site for any purpose without the
                Company express written permission. Additionally, you agree that
                you will not: (i) take any action that imposes, or may impose in
                our sole discretion an unreasonable or disproportionately large
                load on our infrastructure; (ii) interfere or attempt to
                interfere with the proper working of the Site or any activities
                conducted on the Site; or (iii) bypass any measures we may use
                to prevent or restrict access to the Site;{" "}
              </p>
              <p>
                j. To sell or otherwise transfer your profile. By uploading
                Media Material to the Site the Seller has accepted these Terms
                and Conditions and agrees to the following conditions:{" "}
              </p>
              <p>
                k. The Seller owns the exclusive copyright to Media Material and
                the Media Material is original and owned by the Seller;{" "}
              </p>
              <p>
                l. The Seller is offering the Buyer, the Site, the host and the
                Company a non-exclusive, transferable, fully paid world-wide
                license (with the right to sublicense) to the Sellers Media
                Material;{" "}
              </p>
              <p>
                m. In exchange for the non-exclusive license outlined above the
                Buyer will pay the Seller the nominated fee for the Media
                Material less any costs from the host or other parties. The
                license fee terms are laid out in "License Fee and Payment
                Terms";{" "}
              </p>
              <p>
                n. The Site and/or the Company may choose to promote Media
                Material on the Site from time to time and as such may offer
                Sellers a discount on the Media Material offered on the Site.
                The discount due to sales and/or promotion activity will be
                passed on to the Seller as an associated cost of sale as laid
                out in License Fee and Payment Terms;{" "}
              </p>
              <p>
                o. The host is not responsible for any loss or damage to your
                Media Material caused by technical, human, natural or any other
                cause;{" "}
              </p>
              <p>
                p. The host is acting solely to facilitate the Seller to sell
                the Media Material to the Buyer. Any disputes that the Seller or
                buyer have should be resolved by the two parties. The Site will
                not enter into any disputes and the Site will play no part in
                disputes between the Seller or buyer other than providing the
                Seller and buyers email addresses to both parties;{" "}
              </p>
              <p>
                q. Neither the host, the Site or the Company is not responsible
                or liable for the copyright of your Media Material. If a buyer
                or any other party, including anyone that viewed your material
                through the Site, breaches your copyright the host is not
                responsible in any way;{" "}
              </p>
              <p>
                r. If other people or places are shown in your Media Material
                you must gain their written acceptance to sell and distribute
                that information. You are solely responsible for gaining and
                exclusively owning the copyright for your Media Material;{" "}
              </p>
              <p>
                s. The Company reserves the right to remove Media Material from
                the Site or remove the registration of any seller uploading
                Media Material that breeches any of these Terms and Conditions
                from the Site;{" "}
              </p>
              <p>
                t. You are the owner of all rights, including all copyrights in
                and to all Media Material you upload or submit to the Site;{" "}
              </p>
              <p>
                u. You have the full and complete right to enter into this
                agreement and to grant to the Company and the Site the rights in
                the Content herein granted, and that no further permissions are
                required from, nor payments required to be made to any other
                person in connection with the use by the Site of the Media
                Material as contemplated herein;{" "}
              </p>
              <p>
                v. The Content does not defame any person and does not infringe
                upon the copyright, moral rights, publicity rights, privacy
                rights or any other right of any person, or violate any law or
                judicial or governmental order;{" "}
              </p>
              <p>
                w.shall not have any right to terminate the permissions granted
                herein, nor to seek, obtain, or enforce any injunctive or other
                equitable relief against the Site or the Company, all of which
                such rights are hereby expressly and irrevocably waived by you
                in favor of the Site and the Company.{" "}
              </p>

              <p>Forums & Other Interactive Services or Areas</p>
              <p>
                The Site may include discussion forums or other interactive
                areas or services, including blogs, chat rooms, bulletin boards,
                message boards, online hosting or storage services, or other
                areas or services in which you or third parties create, post or
                store any content, messages, comments, materials or other items
                on the Site ("Interactive Areas"). You are solely responsible
                for your use of such Interactive Areas and use them at your own
                risk. By using any Interactive Areas, you agree not to post,
                upload, transmit, distribute, store, create or otherwise publish
                through the Site any of the following:{" "}
              </p>
              <p>
                a. Any message, comment, data, information, text, music, sound,
                photos, graphics, code or other material ("User Content") that
                is unlawful, libelous, defamatory, obscene, pornographic,
                harmful to minors, indecent, lewd, suggestive, harassing,
                threatening, invasive of privacy or publicity rights, abusive,
                inflammatory, fraudulent or otherwise objectionable;{" "}
              </p>
              <p>
                b. User Content that would constitute, encourage or provide
                instructions for a criminal offense;{" "}
              </p>
              <p>
                c. User Content that may infringe any patent, trademark, trade
                secret, copyright or other intellectual property or contract
                right of any party. By posting any User Content, you represent
                and warrant that you have the lawful right to transmit,
                distribute and reproduce such User Content;{" "}
              </p>
              <p>
                d. User Content that impersonates any person or entity or
                otherwise misrepresents your affiliation with a person or
                entity;{" "}
              </p>
              <p>
                e. Unsolicited promotions, political campaigning, advertising,
                junk mail, spam, chain letters, pyramid schemes or
                solicitations;{" "}
              </p>
              <p>
                f. Private information of any third party, including, without
                limitation, addresses, phone numbers, email addresses and credit
                card numbers;{" "}
              </p>
              <p>
                g. Viruses, corrupted data or other harmful, disruptive or
                destructive files; and h. User Content that, in the sole
                judgment of BossFotos, is objectionable or which restricts or
                inhibits any other person from using or enjoying the Interactive
                Areas or the Site, or which adversely affects the availability
                of its resources to other users (e.g., excessive shouting, use
                of all capital letters, or flooding continuous posting of
                repetitive text), or which may expose BossFotos or its users to
                any harm or liability of any type. Further, you agree not to
                delete or revise any User Content posted by any third party.
                BossFotos takes no responsibility and assumes no liability for
                any User Content posted, stored or uploaded by you or any third
                party, or for any loss or damage thereto, nor is BossFotos
                liable for any mistakes, defamation, slander, libel, omissions,
                falsehoods, obscenity, pornography or profanity you may
                encounter. Your use of Interactive Areas is at your own risk. As
                a provider of interactive services, BossFotos is not liable for
                any statements, representations or User Content provided by its
                users in any public forum, personal home page or other
                Interactive Area. Although BossFotos has no obligation to
                screen, edit or monitor any of the User Content posted in any
                Interactive Area, BossFotos reserves the right, and has absolute
                discretion, to remove, screen or edit any User Content posted or
                stored on the Site at any time and for any reason without
                notice. You are solely responsible for creating backup copies of
                and replacing any User Content you post or store on the Site at
                your sole cost and expense. Any use of the Interactive Areas or
                other portions of the Site in violation of the foregoing
                violates these Site Terms and may result in, among other things,
                termination or suspension of your rights to use the Interactive
                Areas and/or the Site. You acknowledge and agree that BossFotos
                may access, use or disclose any information about you or your
                use of this Site, including without limitation any User Content
                to comply with the law or any legal process; protect and defend
                the rights or property of BossFotos; or to protect the safety of
                our company, employees, customers or the public. If you post
                User Content to the Site, unless we indicate otherwise, you
                grant BossFotos and its affiliates a nonexclusive, royalty-free,
                perpetual, irrevocable and fully sublicensable right to use,
                reproduce, modify, adapt, publish, translate, create derivative
                works from, distribute, perform and display such User Content
                throughout the world in any media. You grant BossFotos and its
                affiliates and sublicensees the right to use the name that you
                submit in connection with such content, if they choose. You
                represent and warrant that (i) you own and control all of the
                rights to the User Content that you post or you otherwise have
                the right to post such User Content to the Site; (ii) the User
                Content is accurate and not misleading; and (iii) use and
                posting of the User Content you supply does not violate these
                Site Terms and will not violate any rights of or cause injury to
                any person or entity.{" "}
              </p>
              <p>Registration Data and Account Security</p>
              <p>In consideration of your use of the Site, you agree to: </p>
              <p>
                a. provide accurate, current and complete information about you
                as may be prompted by any registration forms on the Site
                ("Registration Data");{" "}
              </p>
              <p>
                b. maintain and promptly update the Registration Data, and any
                other information you provide to Company, to keep it accurate,
                current and complete;{" "}
              </p>
              <p>
                c.maintain the security of your password and identification;{" "}
              </p>
              <p>
                d. notify BossFotos immediately of any unauthorized use of your
                account or other breach of security;{" "}
              </p>
              <p>
                accept all responsibility for any and all activities that occur
                under your account, including but not limited to, all applicable
                taxes and any applicable third-party fees (including but not
                limited to credit card fees, foreign exchange fees and cross
                border fees); and{" "}
              </p>
              <p>
                f. accept all risks of unauthorized access to the Registration
                Data and any other information you provide to Company.{" "}
              </p>
              <p>Indemnification</p>
              <p>
                You agree to defend, indemnify and hold harmless BossFotos, its
                subsidiaries, affiliates, licensors, employees, agents, third
                party information providers and independent contractors against
                any claims, damages, costs, liabilities and expenses (including,
                but not limited to, reasonable attorneys' fees) arising out of
                or related to any User Content that you post, store or otherwise
                transmit on or through the Site, your conduct, your use or
                inability to use the Site, your breach or alleged breach of the
                Site Terms or of any representation or warranty contained
                herein, your unauthorized use of the BossFotos Content, or your
                violation of any rights of another.{" "}
              </p>
              <p>Disclaimer</p>
              <p>
                THE SITE, INCLUDING WITHOUT LIMITATION THE EMBEDDED VIEWER AND
                THE BossFotos CONTENT, ARE PROVIDED "AS IS" AND BossFotos AND
                ITS DIRECTORS, EMPLOYEES, CONTENT PROVIDERS, AGENTS AND
                AFFILIATES EXCLUDE, TO THE FULLEST EXTENT PERMITTED BY
                APPLICABLE LAW, ANY WARRANTY, EXPRESS OR IMPLIED, INCLUDING,
                WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE.
                BossFotos WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING
                FROM THE USE OF THE SITE OR THE BossFotos CONTENT, OR THE
                UNAVAILABILITY OF THE SAME, INCLUDING, BUT NOT LIMITED TO LOST
                PROFITS, AND DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND
                CONSEQUENTIAL DAMAGES. THE FUNCTIONS EMBODIED ON OR IN THE
                MATERIALS OF THIS SITE ARE NOT WARRANTED TO BE UNINTERRUPTED OR
                WITHOUT ERROR. YOU, NOT BossFotos, ASSUME THE ENTIRE COST OF ALL
                NECESSARY SERVICING, REPAIR OR CORRECTION DUE TO YOUR USE OF
                THIS SITE OR THE BossFotos CONTENT. WE MAKE NO WARRANTY THAT THE
                SITE OR THE BossFotos CONTENT IS FREE FROM INFECTION BY VIRUSES
                OR ANYTHING ELSE THAT HAS CONTAMINATING OR DESTRUCTIVE
                PROPERTIES. BossFotos uses reasonable efforts to ensure the
                accuracy, correctness and reliability of the BossFotos Content,
                but we make no representations or warranties as to the BossFotos
                Content's accuracy, correctness or reliability. BossFotos offers
                a search feature within the Site. BossFotos explicitly disclaims
                any responsibility for the content or availability of
                information contained in our search index or directory.
                BossFotos also disclaims any responsibility for the completeness
                or accuracy of any directory or search result.{" "}
              </p>
              <p>Limitation of Liability</p>
              <p>
                IN NO EVENT SHALL BossFotos, ITS DIRECTORS, MEMBERS, EMPLOYEES
                OR AGENTS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR
                CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND,
                INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR
                LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING
                BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR
                IN ANY WAY CONNECTED WITH THE USE OF THE SITE, THE SERVICES, THE
                BossFotos CONTENT OR THE MATERIALS CONTAINED IN OR ACCESSED
                THROUGH THE SITE, INCLUDING WITHOUT LIMITATION ANY DAMAGES
                CAUSED BY OR RESULTING FROM RELIANCE BY USER ON ANY INFORMATION
                OBTAINED FROM BossFotos, OR THAT RESULT FROM MISTAKES,
                OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS,
                DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY
                FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF
                GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED
                ACCESS TO BossFotos' RECORDS, PROGRAMS OR SERVICES. IN NO EVENT
                SHALL THE AGGREGATE LIABILITY OF BossFotos, WHETHER IN CONTRACT,
                WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR
                IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY,
                ARISING OUT OF OR RELATING TO THE USE OF THE SITE EXCEED ANY
                COMPENSATION YOU PAY, IF ANY, TO BossFotos FOR ACCESS TO OR USE
                OF THE SITE.{" "}
              </p>
              <p>Applicable Law and Venue</p>
              <p>
                Any dispute relating in any way to your use of the Site or the
                BossFotos Content shall be submitted to confidential arbitration
                in Sydney, Australia. The arbitrator's award shall be binding
                and may be entered as a judgment in any court of competent
                jurisdiction. To the fullest extent permitted by applicable law,
                no arbitration under this Agreement shall be joined to an
                arbitration involving any other party subject to the Site Terms.{" "}
              </p>
              <p>Termination</p>
              <p>
                Notwithstanding any of these Site Terms, BossFotos reserves the
                right, without notice and in its sole discretion, to terminate
                your account and/or to block your use of the Site.{" "}
              </p>
              <p>Miscellaneous Provisions</p>
              <p>
                Any waiver of any provision of the Site Terms will be effective
                only if in writing and signed by BossFotos. If any clause in
                these Site Terms is found to be unenforceable, wherever possible
                this will not affect any other clause and each will remain in
                full force and effect. Any rights not expressly{" "}
              </p>
              <p>granted herein are reserved. </p>
              <p>Changes to Site Terms</p>
              <p>
                BossFotos reserves the right to change any of the terms and
                conditions contained in the Site Terms or any policy or
                guideline of the Site, at any time and in its sole discretion.
                When we make changes, we will revise the "last updated" date at
                the top of the Site Terms. Any changes will be effective
                immediately upon posting on the Site. Your continued use of the
                Site following the posting of changes will constitute your
                acceptance of such changes. We encourage you to review the Site
                Terms whenever you visit one
              </p>
              <p>
                FotoBoss Pty Ltd trading as BossFotos – 79 George st,
                Parramatta, NSW 2150, Australia
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Terms;
