import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
  CircularProgress,
  CssBaseline,
  Snackbar,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import AlertDialog from "../../v2_components/AlertDialog";
import { ConfirmProvider } from "material-ui-confirm";
import MuiAlert from "@material-ui/lab/Alert";
import RevolutCheckout from "@revolut/checkout";
import SideBar from "../../layout/sidebar";
import { SimplifyPaymentGateway } from "../../utils/SimplifyPaymentGateway";
import TableTrolley from "./table_v2";
import TopBar from "./topbar/topbar_v2";
import { TrolleySummaryCard } from "../../v2_components/trolley/TrolleySummaryCard";
import { authenticatedTrolleyList } from "../../v2_actions/authenticated_trolley";
import { countryList } from "../../v2_actions/country";
import { fixScrollLockOnMuiPromptDisplay } from "../../v2_actions/nav";
import { green } from "@material-ui/core/colors";
import { makeLogger } from "../../utils/o11y/logger";
import { photographerList } from "../../v2_actions/photographer";
import { revolutTrolley } from "../../v2_actions/revolut_trolley";
import { setMessage } from "../../actions/trolley";
import { trolleyPhotographList } from "../../v2_actions/trolley_photograph";
import { trolleyProcessPayment } from "../../v2_actions/trolley_process_payment";
import { useIsMobile } from "../../actions/ui";
import { sentryApi } from "../../utils/o11y/sentryApi";

const logger = makeLogger({
  enabled: true,
  label: "CHECKOUT_V2",
  color: "red",
});

const ENABLE_PRINT_AND_FRAME_OFFER_DIALOG = false;

const useStyles = makeStyles((theme) => ({
  shoppingTrolleyWrapper: {
    padding: "0px 25px",
    marginBottom: "50px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  photographPrintPagePromptButton: {
    height: 56,
    width: 176,
  },
  photographPrintPagePromptButtonText: {
    fontSize: 16,
    fontWeight: 600,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const yoco = new window.YocoSDK({
  publicKey: window.LOCAL_SETTINGS.YOCO_PUBLIC_KEY,
});

const ShoppingTrolley = () => {
  const { trolley_id: trolleyIdFromUrl } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [showSideBar, setShowSideBar] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [disableCheckoutButton, setDisableCheckoutButton] = useState(false);
  const urlFilter = new window.URLSearchParams(window.location.search);
  const [showingSimplifyPaymentGateway, setShowingSimplifyPaymentGateway] =
    useState(false);
  const [showPostPurchaseRedirectMessage, setShowPostPurchaseRedirectMessage] =
    useState(false);
  const userInfoData = useSelector((state) => state.userReducer.userInfo)
  const trolley_id = useSelector((state) => state.trolleyReducer.trolley_id)
  const trolley = useSelector(() =>
      authenticatedTrolleyList.getObject(trolley_id),
    );
  const loadingTrolley = isEmpty(trolley);
  const photographer_id = get(trolley, "photographer");
  const photographer = useSelector(() =>
    photographerList.getPhotographerForId(photographer_id),
  );
  const [showPrintAndFrameOffer, setShowPrintAndFrameOffer] = useState(
    urlFilter.get("showPrintAndFrameOffer") === "true",
  );
  const trolleyListIsLoading = useSelector(() =>
    authenticatedTrolleyList.isLoading(),
  );
  const trolleyPhotographListIsSavingObject = useSelector(() =>
    trolleyPhotographList.getIsSavingObject(),
  );
  const trolleyPhotographListIsDeletingObject = useSelector(() =>
    trolleyPhotographList.getIsDeletingObject(),
  );
  const trolleyIsUpdating =
    trolleyListIsLoading ||
    trolleyPhotographListIsSavingObject ||
    trolleyPhotographListIsDeletingObject;

  const country_code = useSelector(
    () => trolley && countryList.getCountryCode(trolley.country),
  );
  const payment_gateway = useSelector(
    () => trolley && countryList.getPaymentGateway(trolley.country),
  );
  const galleryCurrencyCode = useSelector(
    () => trolley && countryList.getCurrencyCode(trolley.country),
  );
  const message = useSelector((state) => state.trolleyReducer.message);
  const [showSpinner, setShowSpinner] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const total_price_owing_by_customer = get(
    trolley,
    "total_price_owing_by_customer",
  );
  const total_price_owing_by_customer_in_cents =
    total_price_owing_by_customer * 100;

  if (trolley && !payment_gateway) {
    console.error("legacy_trolley_no_payment_gateway", trolley);
    sentryApi.error("legacy_trolley_no_payment_gateway", trolley);
  }

  useEffect(() => {
    dispatch(authenticatedTrolleyList.ensureObjectLoaded(trolley_id))
  }, [trolley_id])

  useEffect(() => {
    if (trolleyIdFromUrl) {
      authenticatedTrolleyList.setActiveTrolleyId(trolleyIdFromUrl);
      dispatch(authenticatedTrolleyList.ensureTrolleyLoaded());
    }
  }, [trolleyIdFromUrl]);

  useEffect(() => {
    dispatch(photographerList.loadAllPhotographers());
    dispatch(authenticatedTrolleyList.invalidateObject(trolleyIdFromUrl));
    dispatch(authenticatedTrolleyList.updatePaginationNumItemsPerPage(1));
    dispatch(authenticatedTrolleyList.ensureObjectLoaded(trolleyIdFromUrl));
    dispatch(authenticatedTrolleyList.fetchListIfNeeded());
  }, [showPrintAndFrameOffer]);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    if (showPrintAndFrameOffer) {
      navigate(`/customer/shopping/${trolleyIdFromUrl}`);
    } else if (get(trolley, "id") && trolleyIdFromUrl !== trolley.id) {
      navigate(`/customer/shopping/${trolley.id}`);
    }
  }, [JSON.stringify(trolley)]);

  logger.log("render()", trolley);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const onMessage = (msg) => {
    dispatch(setMessage(msg));
    if (msg) {
      setShowSpinner(false);
      setOpenSnackBar(true);
    }
  };

  const createFakePayment = (total_price) => {
    setShowPostPurchaseRedirectMessage(true);
    dispatch(
      trolleyProcessPayment.pay({
        trolley_id: trolley.id,
        gateway_payment_ref: null,
      }),
    ).then(onPaymentSuccessfulAndConfirmed);
  };

  const onPaymentSuccessfulAndConfirmed = () => {
    setShowSpinner(false);
    navigate("/customer/purchased");
    dispatch(authenticatedTrolleyList.resetTrolley());
  };

  const checkOutYoco = async () => {
    if (total_price_owing_by_customer <= 2) {
      createFakePayment(total_price_owing_by_customer);
    } else {
      yoco.showPopup({
        amountInCents: total_price_owing_by_customer_in_cents,
        currency: "ZAR",
        name: "Check Out Trolley",
        description: "Check Out Trolley",
        callback: function (result) {
          if (result.error) {
            setOpenSnackBar(true);
            onMessage(result.error.message);
          } else {
            setShowSpinner(true);
            const payment_token = result.id;
            dispatch(
              trolleyProcessPayment.pay({
                trolley_id: trolley.id,
                gateway_payment_ref: payment_token,
              }),
            ).then(onPaymentSuccessfulAndConfirmed);
          }
        },
      });
    }
  };

  const checkOutSimplify = async () => {
    if (total_price_owing_by_customer <= 2) {
      createFakePayment(total_price_owing_by_customer);
    } else {
      setShowingSimplifyPaymentGateway(true);
    }
  };

  const checkOutRevolut = () => {
    if (total_price_owing_by_customer <= 2) {
      createFakePayment(total_price_owing_by_customer);
    } else {
      const payload = {
        amount: total_price_owing_by_customer_in_cents,
        currency: galleryCurrencyCode.toUpperCase(),
      };

      dispatch(revolutTrolley.prepareOrder({ trolley_id: trolley.id }))
        .then((res) => {
          const public_id = res.public_id;
          RevolutCheckout(public_id, window.LOCAL_SETTINGS.REVOLUT_MODE).then(
            (instance) => {
              instance.payWithPopup({
                name: userInfoData?.last_name,
                email: userInfoData?.email,

                onSuccess() {
                  const payment_token = public_id;
                  dispatch(
                    trolleyProcessPayment.pay({
                      trolley_id: trolley.id,
                      gateway_payment_ref: payment_token,
                    }),
                  ).then(onPaymentSuccessfulAndConfirmed);
                },
                onError(message) {
                  setOpenSnackBar(true);
                  console.error(message);
                  onMessage("The payment processing failed");
                },
                onCancel() {
                  setOpenSnackBar(true);
                  onMessage("Payment cancelled!");
                },
              });
            },
          );
        })
        .catch((err) => {
          console.error(err);
          setOpenSnackBar(true);
          onMessage("Payment preparation failed");
        });
    }
  };

  const checkOut = async () => {
    setDisableCheckoutButton(true);
    if (trolley.id && total_price_owing_by_customer >= 0) {
      switch (payment_gateway) {
        case "yoco":
          checkOutYoco();
          break;
        case "simplify":
          checkOutSimplify();
          break;
        case "revolut":
          checkOutRevolut();
          break;
        default:
          console.error(`Unknown payment gateway: ${payment_gateway}`);
          onMessage(
            "Payment preparation failed, couldn't find payment gateway",
          );
          break;
      }
    } else {
      navigate(`/customer/events`);
    }
  };

  const goToPrintOptionsConfigPage = () => {
    navigate(`/customer/v2_trolley`);
    fixScrollLockOnMuiPromptDisplay();
  };

  const hidePrintAndFrameOffer = () => {
    setShowPrintAndFrameOffer(false);
    fixScrollLockOnMuiPromptDisplay();
  };

  const renderMobileView = () => {
    return (
      <>
        {!loadingTrolley && (
          <>
            <Grid
              container
              component="main"
              className={classes.shoppingTrolleyWrapper}
            >
              <CssBaseline />
              <Grid item xs={12} md={1} lg={4.5}>
                <Box>
                  <TrolleySummaryCard
                    trolley={trolley}
                    checkOut={checkOut}
                    photographer={photographer}
                    disableCheckoutButton={disableCheckoutButton}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={1} lg={3}></Grid>
              <Grid item xs={12} md={4} lg={4.5}>
                <ConfirmProvider>
                  <TableTrolley
                    photographer={photographer}
                    trolleyIsUpdating={trolleyIsUpdating}
                  />
                </ConfirmProvider>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <>
        {!loadingTrolley && (
          <>
            <Grid
              container
              component="main"
              className={classes.shoppingTrolleyWrapper}
            >
              <CssBaseline />
              <Grid item xs={12} md={6} lg={4.5}>
                <ConfirmProvider>
                  <TableTrolley
                    photographer={photographer}
                    trolleyIsUpdating={trolleyIsUpdating}
                  />
                </ConfirmProvider>
              </Grid>
              <Grid item xs={12} md={1} lg={3}></Grid>
              <Grid item xs={12} md={4} lg={4.5}>
                <Box>
                  <TrolleySummaryCard
                    trolley={trolley}
                    checkOut={checkOut}
                    photographer={photographer}
                    trolleyIsUpdating={trolleyIsUpdating}
                    disableCheckoutButton={disableCheckoutButton}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  const renderPhotographPrintPagePromptOptions = () => {
    return (
      <Stack
        alignItems="center"
        spacing={{ md: 4 }}
        mb={{ xs: 3, md: 0 }}
        justifyContent="space-evenly"
        direction={{ xs: "column-reverse", md: "row" }}
      >
        <Button
          variant="contained"
          onClick={goToPrintOptionsConfigPage}
          className={classes.photographPrintPagePromptButton}
        >
          <p className={classes.photographPrintPagePromptButtonText}>Yes</p>
        </Button>
        <Button
          variant="contained"
          onClick={hidePrintAndFrameOffer}
          className={classes.photographPrintPagePromptButton}
        >
          <p className={classes.photographPrintPagePromptButtonText}>
            No. Proceed to Check Out
          </p>
        </Button>
      </Stack>
    );
  };

  return (
    <div style={{ overflowY: "scroll", height: "100vh" }}>
      {showingSimplifyPaymentGateway && (
        <SimplifyPaymentGateway
          onClose={() => setShowingSimplifyPaymentGateway(false)}
          trolley={trolley}
          onPaymentSuccessfulAndConfirmed={onPaymentSuccessfulAndConfirmed}
          onPaymentFailure={(msg) => {
            setShowingSimplifyPaymentGateway(false);
            setShowSpinner(false);
            onMessage(msg);
          }}
          showSpinner={setShowSpinner}
        />
      )}
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        style={{ height: "calc(100vh - 90px)" }}
      >
        <Alert onClose={() => setOpenSnackBar(false)} severity="error">
          {message}
        </Alert>
      </Snackbar>
      {(showSpinner || loadingTrolley) && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: 60,
            height: 60,
            color: green[500],
            zIndex: 999,
          }}
        />
      )}

      {trolley && !loadingTrolley && (
        <>
          <TopBar checkOut={checkOut} galleryId={trolley.gallery} />
          <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
          {ENABLE_PRINT_AND_FRAME_OFFER_DIALOG && (
            <AlertDialog
              openAlertDialog={showPrintAndFrameOffer}
              setOpenAlertDialog={setShowPrintAndFrameOffer}
              heading="Do you want to print or frame any of your images?"
              actions={renderPhotographPrintPagePromptOptions()}
            />
          )}
          <div style={{ paddingTop: !isMobile ? 65 : 0 }}>
            {!isMobile && renderDesktopView()}
            {isMobile && renderMobileView()}
            {showPostPurchaseRedirectMessage && (
              <AlertDialog
                allowClose={false}
                disablePortal={false}
                openAlertDialog={showPostPurchaseRedirectMessage}
                setOpenAlertDialog={setShowPostPurchaseRedirectMessage}
                heading="Thank you for your purchase."
                body="Please be patient. We are creating your photos and will redirect you. Please don't click on anything while this happens. Thanks"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ShoppingTrolley;
