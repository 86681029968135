import size from "lodash/size";
import map from "lodash/map";
import indexOf from "lodash/indexOf";
import includes from "lodash/includes";
import head from "lodash/head";
import get from "lodash/get";
import at from "lodash/at";
import * as yup from "yup";

import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material/";
import { Controller, useForm } from "react-hook-form";
import { Global, css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Description } from "./Description";
import { DialogPrompt } from "./DialogPrompt";
import { MaterialDropdown } from "../form/MaterialDropdown";
import { PrintTypeDescription } from "./PrintTypeDescription";
import { TrolleyPhotographPrice } from "./TrolleyPhotographPrice";
import { authenticatedTrolleyList } from "../../v2_actions/authenticated_trolley";
import { countryList } from "../../v2_actions/country";
import { getCheckoutNavBackButtonUrl } from "../../v2_actions/nav";
import { handleSubmitResult } from "../../v2_actions/form";
import { makeStyles } from "@material-ui/core";
import { photographerList } from "../../v2_actions/photographer";
import { printPriceConfigurationList } from "../../v2_actions/print_price_configuration";
import { trolleyList } from "../../v2_actions/trolley";
import { trolleyPhotographList } from "../../v2_actions/trolley_photograph";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles({
  deliveryTypeRadioGroup: {
    "& div": {
      display: "flex",
      justifyContent: "space-around",
      "& label": {
        marginLeft: "0px",
        "& span": {
          color: "#000",
          fontWeight: "600",
          fontSize: 14,
        },
      },
    },
  },
  noDeliveryTypeSelected: {
    "& div": {
      "& label": {
        color: "#0c59cf",
        "& span": {
          color: "#0c59cf",
          fontWeight: "600",
          fontSize: 14,
        },
      },
    },
  },
  promptSelectPurchaseOption: {
    color: "#0c59cf",
    fontWeight: "600",
    fontSize: "14px",
    textAlign: "center",
  },
  nextPristineField: {
    "& label": {
      color: "#fff",
    },
    "& div": {
      "& fieldset": {
        border: "solid 2px #1464f6",
        background: "#1464f6",
      },
      "& svg": {
        fill: "#fff",
        zIndex: 9,
      },
    },
  },
  dirtyField: {
    "& div": {
      "& fieldset": {
        border: "solid 2px black",
        background: "transparent",
        color: "#000",
      },
      "& svg": {
        fill: "#000",
        zIndex: 9,
      },
    },
  },
});

const global_styles = css`
  form {
    width: 100%;
  }
  button.MuiButton-root {
    line-height: 1.5;
    width: 133px;
    height: 28px;
    text-transform: capitalize;
  }
  span.MuiFormControlLabel-label {
    font-size: 13px;
    @media only screen and (max-width: 450px) {
      font-size: 11.5px;
    }
  }
  .Mui-checked + span {
    font-weight: 600;
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .MuiInputBase-input span {
    font-weight: 600;
    position: relative;
    bottom: 2px;
  }
  .MuiSelect-outlined.MuiSelect-outlined span {
    font-weight: 600;
    position: relative;
    bottom: 3px;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiSelect-icon {
    top: calc(50% - 14px);
  }

  .MuiOutlinedInput-input {
    padding: 8.5px 14px;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1);
  }

  .MuiInputLabel-shrink {
    display: none;
  }

  .MuiOutlinedInput-notchedOutline {
    top: -2px;
  }

  legend {
    display: none !important;
  }
`;

const formSchema = yup.object().shape();

export const TrolleyPhotographForm = ({
  trolley,
  handleNextPage,
  onPrintTypeIdChanged,
  active_trolley_photograph,
  onPhotoDeliveryTypeChanged,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const photographer_id = get(trolley, "photographer");
  const trolley_photos = get(trolley, "trolley_photographs");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [copyToRemainingPhotographs, setCopyToRemainingPhotographs] =
    useState(null);
  const [askApplyPrintOptionsToAllPhotos, setAskApplyPrintOptionsToAllPhotos] =
    useState(false);
  const country_id = useSelector((state) => state.userReducer.userInfo?.country)
  const user_country = dispatch(countryList.getObject(country_id))
  const country_print_configuration_id = get(
    user_country,
    "country_print_configuration",
  );
  const photographer = useSelector(() =>
    photographerList.getPhotographerForId(photographer_id),
  );
  const indexOfTrolleyPhotograph = indexOf(
    map(trolley_photos, (x) => x.id),
    get(active_trolley_photograph, "id"),
    0,
  );
  const nextTrolleyPhotographId = get(
    head(at(trolley_photos, indexOfTrolleyPhotograph + 1)),
    "id",
  );
  const country_print_configuration_for_active_photograph = useSelector(() =>
    printPriceConfigurationList.getObject(
      get(active_trolley_photograph, "country_print_price"),
    ),
  );

  const formProps = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      print_type: null,
      print_size: null,
      print_frame: null,
      print_paper: null,
      photo_delivery_type: null,
    },
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formProps;

  const is_watched = watch();
  const print_enabled = includes(
    ["digital_plus_prints", "prints_only"],
    getValues("photo_delivery_type"),
  );

  const selectedPrintSizeName = useSelector(() =>
    printPriceConfigurationList.getPrintSizeNameForSelectedOptions({
      selectedPrintTypeOption: getValues("print_type"),
      selectedPrintSizeOption: getValues("print_size"),
    }),
  );

  const {
    availablePrintTypeOptions,
    availablePrintSizeOptions,
    availablePrintFrameOptions,
    availablePrintPaperOptions,
    priceOfPrintConfiguration,
  } = useSelector(() =>
    printPriceConfigurationList.getAvailableOptions({
      selectedPrintTypeOption: getValues("print_type"),
      selectedPrintSizeOption: getValues("print_size"),
      selectedPrintFrameOption: getValues("print_frame"),
      selectedPrintPaperOption: getValues("print_paper"),
    }),
  );

  const lowestPriceOfPrintConfiguration = useSelector(() =>
    printPriceConfigurationList.getLowestPriceOfPrintConfiguration({
      selectedPrintTypeOption: getValues("print_type"),
      selectedPrintSizeOption: getValues("print_size"),
      selectedPrintFrameOption: getValues("print_frame"),
      selectedPrintPaperOption: getValues("print_paper"),
    }),
  );

  const selected_print_price_configuration = useSelector(() =>
    printPriceConfigurationList.getPrintPriceForSelectedOptions({
      selectedPrintTypeOption: getValues("print_type"),
      selectedPrintSizeOption: getValues("print_size"),
      selectedPrintFrameOption: getValues("print_frame"),
      selectedPrintPaperOption: getValues("print_paper"),
    }),
  );

  const selected_print_price_configuration_description = get(
    selected_print_price_configuration,
    "description",
  );

  const printTypeId = watch("print_type");

  useEffect(() => {
    dispatch(countryList.ensureObjectLoaded(country_id))
  }, [country_id])

  useEffect(() => {
    onPrintTypeIdChanged(printTypeId);
    setValue("print_size", null);
    setValue("print_frame", null);
    setValue("print_paper", null);
  }, [printTypeId]);

  useEffect(() => {
    if (size(availablePrintTypeOptions) === 1) {
      setValue("print_type", get(head(availablePrintTypeOptions), "value"));
    }
    if (size(availablePrintSizeOptions) === 1) {
      setValue("print_size", get(head(availablePrintSizeOptions), "value"));
    }
    if (size(availablePrintFrameOptions) === 1) {
      setValue("print_frame", get(head(availablePrintFrameOptions), "value"));
    }
    if (size(availablePrintPaperOptions) === 1) {
      setValue("print_paper", get(head(availablePrintPaperOptions), "value"));
    }
  }, [
    getValues("print_type"),
    getValues("print_size"),
    getValues("print_frame"),
    getValues("print_paper"),
  ]);

  useEffect(() => {
    onPhotoDeliveryTypeChanged(getValues("photo_delivery_type"));
  }, [getValues("photo_delivery_type")]);

  useEffect(() => {
    setValue(
      "print_type",
      get(country_print_configuration_for_active_photograph, null) || null,
    );
    setValue(
      "print_size",
      get(
        country_print_configuration_for_active_photograph,
        ["print_size", "id"],
        null,
      ) || null,
    );
    setValue(
      "print_frame",
      get(
        country_print_configuration_for_active_photograph,
        ["print_frame", "id"],
        null,
      ) || null,
    );
    setValue(
      "print_paper",
      get(
        country_print_configuration_for_active_photograph,
        ["print_paper", "id"],
        null,
      ) || null,
    );
  }, [
    JSON.stringify(active_trolley_photograph),
    JSON.stringify(country_print_configuration_for_active_photograph),
  ]);

  useEffect(() => {
    setValue(
      "photo_delivery_type",
      get(active_trolley_photograph, "photo_delivery_type", null),
    );
  }, [JSON.stringify(active_trolley_photograph)]);

  useEffect(() => {
    dispatch(printPriceConfigurationList.fetchListIfNeeded());
    dispatch(photographerList.loadAllPhotographers());
    dispatch(
      printPriceConfigurationList.trySetFilter({
        country_print_configuration: country_print_configuration_id,
        enabled: true,
      }),
    );
  }, [user_country]);

  const onDeletePhoto = () => {
    const indexOfFirstTrolleyPhotograph = 0;
    const lengthOfTrolleyPhotos = size(trolley_photos);
    const prevTrolleyPhotographId = get(
      head(at(trolley_photos, indexOfTrolleyPhotograph - 1)),
      "id",
    );
    dispatch(
      trolleyPhotographList.removePhotograph(
        get(active_trolley_photograph, ["photograph", "id"]),
      ),
    );

    if (indexOfTrolleyPhotograph > indexOfFirstTrolleyPhotograph) {
      navigate(`/customer/v2_trolley/${prevTrolleyPhotographId}`);
    } else if (
      indexOfTrolleyPhotograph === indexOfFirstTrolleyPhotograph &&
      lengthOfTrolleyPhotos - 1 > 0
    ) {
      navigate(`/customer/v2_trolley/${nextTrolleyPhotographId}`);
    } else {
      navigate(getCheckoutNavBackButtonUrl());
    }
  };

  const onChangeImageDeliveryType = (event) => {
    const photo_delivery_type = event.target.value;
    setValue("photo_delivery_type", photo_delivery_type);
    const isDigitalDeliveryType =
      trolleyPhotographList.isDigitalDeliveryType(photo_delivery_type);

    if (isDigitalDeliveryType) {
      onPrintTypeIdChanged(null);
    } else {
      onPrintTypeIdChanged(printTypeId);
    }
  };

  const onSelectedApplyPrintOptionsToAllPhotos = (event) => {
    const applyToAllPhotos = event.target.value === "true";
    setCopyToRemainingPhotographs(applyToAllPhotos);
    setAskApplyPrintOptionsToAllPhotos(false);
    onSave({
      values: getValues(),
      forceCopyToRemainingPhotographs: applyToAllPhotos,
    });
  };

  const onNextPage = () => {
    //    setIsSubmitButtonDisabled(false)
    if (nextTrolleyPhotographId) {
      navigate(`/customer/v2_trolley/${nextTrolleyPhotographId}`);
    } else if (
      !nextTrolleyPhotographId &&
      getValues("photo_delivery_type") === "digital_only"
    ) {
      navigate(`/customer/shopping/${get(trolley, "id")}`);
    } else {
      navigate("/customer/shipping_address");
    }
  };

  const onSave = ({ values, forceCopyToRemainingPhotographs }) => {
    setIsSubmitButtonDisabled(true);
    const hasMorePhotos = size(trolley_photos) > 1;
    const isFirstPhoto = indexOfTrolleyPhotograph === 0;
    const copyToRemainingPhotographsIsSet =
      forceCopyToRemainingPhotographs !== undefined ||
      copyToRemainingPhotographs !== null;
    const localCopyToRemainingPhotographs =
      forceCopyToRemainingPhotographs === true ||
      copyToRemainingPhotographs === true;

    if (
      isFirstPhoto &&
      hasMorePhotos &&
      !copyToRemainingPhotographsIsSet &&
      get(active_trolley_photograph, "photo_delivery_type") === null
    ) {
      setAskApplyPrintOptionsToAllPhotos(true);
      return null;
    }

    const photoDeliveryType = values.photo_delivery_type;
    switch (photoDeliveryType) {
      case "digital_only":
        setValue("quality", "high-res");
        break;
      case "low_res_digital_only":
        setValue("quality", "low-res");
        break;
      default:
        setValue("quality", "high-res");
    }

    let on_ok = function (json) {
      dispatch(trolleyList.invalidateObject(trolley.id));
      dispatch(trolleyList.ensureObjectLoaded(trolley.id));
      dispatch(authenticatedTrolleyList.invalidateObject(trolley.id));
      dispatch(authenticatedTrolleyList.ensureObjectLoaded(trolley.id));
      if (localCopyToRemainingPhotographs === true) {
        if (print_enabled) {
          navigate("/customer/shipping_address");
        } else {
          navigate(`/customer/shopping/${get(trolley, "id")}`);
        }
      } else {
        onNextPage();
      }
      setIsSubmitButtonDisabled(false);
    };

    values.id = active_trolley_photograph.id;
    values.trolley = active_trolley_photograph.trolley;
    values.copy_to_remaining_photographs = localCopyToRemainingPhotographs;
    values.trolley_photograph_form = true;
    values.country_print_price = get(selected_print_price_configuration, "id");

    console.log(values);
    return dispatch(trolleyPhotographList.saveObject(values)).then((res) =>
      handleSubmitResult({ res, formProps, on_ok }),
    );
  };

  const renderDeliveryTypes = () => {
    return (
      <>
        <Grid
          className={[
            getValues("photo_delivery_type") === null &&
              classes.noDeliveryTypeSelected,
            classes.deliveryTypeRadioGroup,
          ]}
        >
          {getValues("photo_delivery_type") === null && (
            <p className={classes.promptSelectPurchaseOption}>
              Choose your purchase option for this photo
            </p>
          )}
          <Controller
            name="photo_delivery_type"
            control={control}
            render={({ field }) => (
              <RadioGroup
                row
                value={field.value}
                onChange={(event) => onChangeImageDeliveryType(event, field)}
              >
                <FormControlLabel
                  labelPlacement="start"
                  value="digital_only"
                  control={<Radio />}
                  label="High Res Photo"
                />
                {get(active_trolley_photograph, "low_res_price") !== null && (
                  <FormControlLabel
                    labelPlacement="start"
                    value="low_res_digital_only"
                    control={<Radio />}
                    label="Low Res Photo"
                  />
                )}
                <FormControlLabel
                  labelPlacement="start"
                  value="digital_plus_prints"
                  control={<Radio />}
                  label="Digital + Frame Prints"
                />
              </RadioGroup>
            )}
          />
        </Grid>
      </>
    );
  };

  const renderPrintOptionsForm = () => {
    const printTypeHasValue = print_enabled && getValues("print_type") !== null;
    const printSizeHasValue = print_enabled && getValues("print_size") !== null;
    const printFrameHasValue =
      print_enabled && getValues("print_frame") !== null;
    const printPaperHasValue =
      print_enabled && getValues("print_paper") !== null;

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderDeliveryTypes()}
          </Grid>
          {print_enabled && (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  name="print_type"
                  control={control}
                  render={({ field }) => (
                    <>
                      <MaterialDropdown
                        enabled={print_enabled}
                        form={formProps}
                        options={availablePrintTypeOptions}
                        additionalClasses={[
                          print_enabled && classes.nextPristineField,
                          print_enabled &&
                            getValues("print_type") &&
                            classes.dirtyField,
                        ]}
                        placeholder="Print Type"
                        variant="outlined"
                        {...field}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="print_size"
                  control={control}
                  render={({ field }) => (
                    <MaterialDropdown
                      form={formProps}
                      options={availablePrintSizeOptions}
                      placeholder="Print Size"
                      enabled={printTypeHasValue}
                      variant="outlined"
                      additionalClasses={[
                        printTypeHasValue && classes.nextPristineField,
                        printSizeHasValue && classes.dirtyField,
                      ]}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="print_frame"
                  control={control}
                  render={({ field }) => (
                    <MaterialDropdown
                      form={formProps}
                      options={availablePrintFrameOptions}
                      placeholder="Print Frame"
                      enabled={printSizeHasValue}
                      variant="outlined"
                      additionalClasses={[
                        printSizeHasValue && classes.nextPristineField,
                        printFrameHasValue && classes.dirtyField,
                      ]}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="print_paper"
                  control={control}
                  render={({ field }) => (
                    <MaterialDropdown
                      form={formProps}
                      options={availablePrintPaperOptions}
                      enabled={printFrameHasValue}
                      variant="outlined"
                      placeholder="Print Paper"
                      additionalClasses={[
                        printFrameHasValue && classes.nextPristineField,
                        printPaperHasValue && classes.dirtyField,
                      ]}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}></Grid>
        </Grid>
      </>
    );
  };

  const renderApplyPrintOptionsToAllPhotos = () => {
    return (
      <DialogPrompt
        title="Apply my selections to all photos?"
        txt_one="No. I would like to customise my printing selections for each photo"
        txt_two="Yes. Copy my selection to all photographs and proceed to check out"
        btn_one_txt="Customise Each Photo"
        btn_two_txt="Proceed to check out"
        handleClose={onSelectedApplyPrintOptionsToAllPhotos}
      />
    );
  };

  const renderShippingLocation = () => {
    return (
      <Typography component="p" variant="p" sx={{ cursor: "pointer" }}>
        Shipping to: {get(user_country, "country_name")}
      </Typography>
    );
  };

  const renderAddToTrolleyButton = () => {
    const doesActiveTrolleyPhotographHaveADeliveryType = getValues(
      "photo_delivery_type",
    );
    const enabled =
      Boolean(doesActiveTrolleyPhotographHaveADeliveryType) &&
      (getValues("photo_delivery_type") === "digital_only" ||
        getValues("photo_delivery_type") === "low_res_digital_only" ||
        Boolean(getValues("print_paper")));

    return (
      <>
        {enabled && (
          <>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitButtonDisabled}
            >
              Confirm item
            </Button>
          </>
        )}
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit((values) => onSave({ values }))}>
      <Global styles={global_styles} />
      <Grid container>{renderPrintOptionsForm()}</Grid>
      <Grid container mb={2}>
        <Grid item xs>
          {getValues("photo_delivery_type") !== "digital_only" &&
            getValues("photo_delivery_type") !== "low_res_digital_only" &&
            getValues("photo_delivery_type") !== null &&
            renderShippingLocation()}
        </Grid>
        <Grid item>
          <Typography component="b" variant="b">
            <TrolleyPhotographPrice
              photographer={photographer}
              user_country={user_country}
              photo_delivery_type={getValues("photo_delivery_type")}
              active_trolley_photograph={active_trolley_photograph}
              priceOfPrintConfiguration={lowestPriceOfPrintConfiguration}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        {indexOfTrolleyPhotograph != -1 && (
          <>
            <Grid item xs>
              <Button
                variant="contained"
                onClick={onDeletePhoto}
                sx={{ bgcolor: "#ebebeb", color: "#000000" }}
              >
                Remove Item
              </Button>
            </Grid>
            <Grid item>{renderAddToTrolleyButton()}</Grid>
          </>
        )}
      </Grid>

      {print_enabled && (
        <Grid>
          <Grid item md={12}>
            <Description
              title="Frame Type"
              description={selected_print_price_configuration_description}
            />
            <Description
              title="Photo Size"
              description={selectedPrintSizeName}
            />
            <PrintTypeDescription print_type_id={getValues("print_type")} />
          </Grid>
        </Grid>
      )}
      {askApplyPrintOptionsToAllPhotos && renderApplyPrintOptionsToAllPhotos()}
    </form>
  );
};
