import get from "lodash/get";
import size from "lodash/size";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import { http } from "../lib";
import cookie from "react-cookies";
import { setMapState } from "./map";
import { showError } from "./error";
import { guessCountryCode } from "../utils/detectCountryCode";
import { countryList } from "./country";
import { trolleyList } from "./trolley";
import { clearTrolleyID } from "../actions/trolley";
import { authenticatedTrolleyList } from "./authenticated_trolley";
import { sentryApi } from "../utils/o11y/sentryApi";
import { deleteAllCookies } from "../utils/deleteAllCookies";
import { store } from "../index"
import { setShowReteralModal, userInfo, clearUser } from "../actions/user"

export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const ANNOUNCE_LOGGING_IN_USER = "ANNOUNCE_LOGGING_IN_USER";
export const ANNOUNCE_USER_NOT_LOGGED_IN = "ANNOUNCE_USER_NOT_LOGGED_IN";
export const SET_CURRENCY_SYMBOL = "SET_CURRENCY_SYMBOL"


export function announceUserNotLoggedIn() {
  return {
    type: ANNOUNCE_USER_NOT_LOGGED_IN,
  };
}

export function announceUserLoggedIn(token, user) {
  return async (dispatch, getState) => {
    dispatch(updatePreferredLocation(user));
    dispatch({
      type: USER_LOGGED_IN,
      token,
      user,
    });
  };
}

export function announceLoggingInUser() {
  return {
    type: ANNOUNCE_LOGGING_IN_USER,
  };
}

export function signUp(
  data,
  navigate,
  setOpenSnackBar,
  setErrorMessage,
  trolley_id_from_url,
  trolley,
) {
  return async (dispatch, getState) => {
    try {
      sentryApi.breadcrumb({
        category: "signup",
        level: "info",
        message: "start",
        data: {
          email: data.email,
          cookies: Object.keys(cookie?.loadAll()),
          trolley_id_from_url,
        },
      });

      dispatch(announceLoggingInUser());
      let [json, success] = await http.post(getState(), "signup/signup", {
        data,
      });

      if (success.status === 200 && has(json, "token")) {
        const userInfoData = json.user;

        dispatch(announceUserLoggedIn(json.token, json.user));
        dispatch(authenticatedTrolleyList.trySetTrolleyCustomer());
        dispatch(setShowReteralModal(true));
        dispatch(userInfo(userInfoData));

        if (
          size(get(trolley, "trolley_photographs")) > 0 ||
          trolley_id_from_url
        ) {
          navigate(
            `/customer/shopping/${
              trolley_id_from_url || get(trolley, "id")
            }/?showPrintAndFrameOffer=true`,
          );
        } else {
          navigate(`/customer/events`);
        }
      } else if (success.status === 409) {
        setOpenSnackBar(true);
        setErrorMessage(
          "This email address has already been used. Please click “Login” above",
        );
        return;
      } else {
        sentryApi.error("signup_http_error_non_200", {
          httpstatuscode: success.status,
        });
        dispatch(announceUserNotLoggedIn());
      }

      if (!json.token) {
        setOpenSnackBar(true);
        setErrorMessage(
          "This email address has already been used. The same email cannot be used to register as a customer and a photographer",
        );
        sentryApi.error("signup_error_email_in_use", {});
      } else {
        sentryApi.breadcrumb({
          category: "signup",
          level: "info",
          message: "success",
          data: {},
        });
      }
    } catch (e) {
      setOpenSnackBar(true);
      setErrorMessage(`Failed: ${e}`);
      console.error(e.message);
      sentryApi.error("signup_error", { error: "unknown", e });
    }
  };
}

export function clearAuthentication() {
  return async(dispatch, getState) => {
    dispatch(clearTrolleyID())
    dispatch(clearUser())
    dispatch(announceUserNotLoggedIn())
    sentryApi.breadcrumb({
      category: "logout",
      level: "info",
      message: "logout",
      data: {},
    });
  }
}

export function updatePreferredLocation(user) {
  return async (dispatch, getState) => {
    if (user.preferred_lat && user.preferred_lng) {
      setStoredLatLng({ lat: user.preferred_lat, lng: user.preferred_lng });
    } else {
      guessCountryCode(({ country_code, position }) => {
        setStoredLatLng(position);
        if (position.lat && position.lng) {
          setMapState(position);
        }
      });
    }
  };
}

export const getStoredLatLng = () => {
  return {
    lat: parseFloat(window.localStorage.getItem("lat")),
    lng: parseFloat(window.localStorage.getItem("lng")),
  };
};

export const setStoredLatLng = ({ lat, lng }) => {
  if (lat && lng) {
    window.localStorage.setItem("lat", lat);
    window.localStorage.setItem("lng", lng);
  }
};
