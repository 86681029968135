import map from "lodash/map";
import get from "lodash/get";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { css, Global } from "@emotion/react";
import { printPriceConfigurationList } from "../../v2_actions/print_price_configuration";
import { Typography } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@material-ui/core";
import { useIsMobile } from "../../actions/ui";
import { PhotoCostSummary } from "./PhotoCostSummary";
import TableContainer from "@mui/material/TableContainer";
import { CheckoutTrolleyPhotographThumbnail } from "../../v2_components/trolley/CheckoutTrolleyPhotographThumbnail";
import { trolleyPhotographList } from "../../v2_actions/trolley_photograph";
import { authenticatedTrolleyList } from "../../v2_actions/authenticated_trolley";
import { trolleyList } from "../../v2_actions/trolley";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "& td": {
      "& b": {
        fontWeight: 600,
      },
      "& span": {
        fontWeight: 600,
      },
    },
  },
  itemRowWrapper: {
    display: "flex !important",
    width: "100%",
    borderBottom: "solid 1px #c0c0c0",
    paddingBottom: "10px",
    paddingTop: "7px",
  },
  borderBottom: {
    borderBottom: "solid 1px #c0c0c0",
  },
  trolleyItemsTable: {
    tableLayout: "auto",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      tableLayout: "fixed",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: 15,
      position: "relative",
    },
  },
}));

const global_styles = css`
  .MuiTableContainer-root {
    @media only screen and (max-width: 450px) {
      padding: 0px 15px;
    }
  }

  .MuiTableCell-root .MuiButton-root {
    text-transform: capitalize;
    text-decoration: underline;
    color: #000;
  }
  .MuiTableRow-root {
    width: 100%;
    display: table !important;
  }
  .MuiTableCell-root {
    border-bottom: 0px !important;
  }
  .MuiTableRow-root td {
    margin-left: 0px;
    padding: 8.8px 0px;
    //  font-weight: 600;
    width: inherit;
  }
`;

export default function TrolleyTable({
  shipping_country,
  photographer,
  trolleyIsUpdating,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const trolley_id = useSelector((state) => state.trolleyReducer.trolley_id)
  const trolley = useSelector(() =>
    authenticatedTrolleyList.getObject(trolley_id),
  );

  useEffect(() => {
    dispatch(authenticatedTrolleyList.ensureObjectLoaded(trolley_id))
  }, [trolley_id])

  useEffect(() => {
    dispatch(trolleyList.fetchListIfNeeded());
    dispatch(authenticatedTrolleyList.fetchListIfNeeded());
    dispatch(printPriceConfigurationList.fetchListIfNeeded());
  }, []);

  const onDeletePhoto = (trolley_photograph) => {
    dispatch(
      trolleyPhotographList.removePhotograph(
        get(trolley_photograph, ["photograph", "id"]),
      ),
    );
  };

  const renderCheckoutItemTitle = (trolley_photograph) => {
    const price = get(trolley_photograph, "price");
    const lowResPrice = get(trolley_photograph, "low_res_price");
    const photo_delivery_type = get(trolley_photograph, "photo_delivery_type");
    const isDigitalDeliveryType =
      trolleyPhotographList.isDigitalDeliveryType(photo_delivery_type);

    if (isDigitalDeliveryType) {
      return (
        <Typography variant="p" gutterBottom component="b">
          {price === lowResPrice
            ? "Low Res Digital Copy"
            : "High Res Digital Copy"}
        </Typography>
      );
    } else {
      return (
        <Typography variant="p" gutterBottom component="b">
          {get(
            printPriceConfigurationList.getObject(
              trolley_photograph.country_print_price,
            ),
            ["print_type", "name"],
          )}
        </Typography>
      );
    }
  };

  const renderRemoveButton = (trolley_photograph) => (
    <Button
      disabled={trolleyIsUpdating}
      onClick={() => onDeletePhoto(trolley_photograph)}
    >
      Remove
    </Button>
  );

  const renderTrolleyItems = () => {
    return (
      <>
        <Grid container>
          {map(trolley.trolley_photographs, (trolley_photograph) => (
            <>
              <div className={[classes.itemRowWrapper]}>
                <Grid item lg={4}>
                  <TableContainer>
                    <Table className={classes.trolleyItemsTable}>
                      <TableBody>
                        <div key={get(trolley_photograph, "id")}>
                          <TableRow className={classes.tableRow}>
                            <CheckoutTrolleyPhotographThumbnail
                              trolleyIsUpdating={trolleyIsUpdating}
                              trolley_photograph={trolley_photograph}
                            />
                          </TableRow>
                        </div>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item lg={8}>
                  <TableContainer>
                    <Table className={classes.trolleyItemsTable}>
                      <TableBody>
                        <div key={get(trolley_photograph, "id")}>
                          <TableRow className={classes.tableRow}>
                            <TableCell>
                              {renderCheckoutItemTitle(trolley_photograph)}
                            </TableCell>
                            <TableCell>
                              {renderRemoveButton(trolley_photograph)}
                            </TableCell>
                          </TableRow>
                          <PhotoCostSummary
                            trolley={trolley}
                            photographer={photographer}
                            trolley_photograph={trolley_photograph}
                          />
                        </div>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </div>
            </>
          ))}
        </Grid>
      </>
    );
  };

  const renderMobileTrolleyItems = () => {
    return (
      <Grid container>
        {map(trolley.trolley_photographs, (trolley_photograph) => (
          <>
            <TableContainer>
              <Grid item xs={12}>
                <div key={get(trolley_photograph, "id")}>
                  <TableRow className={classes.tableRow}>
                    <CheckoutTrolleyPhotographThumbnail
                      trolleyIsUpdating={trolleyIsUpdating}
                      trolley_photograph={trolley_photograph}
                    />
                  </TableRow>
                </div>
              </Grid>
              <Table className={classes.trolleyItemsTable}>
                <TableBody>
                  <div key={get(trolley_photograph, "id")}>
                    <TableRow className={classes.tableRow}>
                      <TableCell>
                        {renderCheckoutItemTitle(trolley_photograph)}
                      </TableCell>
                      <TableCell>
                        {renderRemoveButton(trolley_photograph)}
                      </TableCell>
                    </TableRow>
                    <PhotoCostSummary
                      trolley={trolley}
                      photographer={photographer}
                      trolley_photograph={trolley_photograph}
                    />
                  </div>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Global styles={global_styles} />
      {!isMobile && renderTrolleyItems()}
      {isMobile && renderMobileTrolleyItems()}
    </>
  );
}
